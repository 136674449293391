import { backendAuthenticatedAxios } from '..';
import { BackendUserResponse } from '../types';

import { AxiosResponse } from 'axios';

interface UpdateFPTData {
    uid?: string;
    version?: number;
    funding_amount?: number;
}

const updateUserFPT = async (
  fptData: UpdateFPTData,
): Promise<AxiosResponse<BackendUserResponse>> => {
  console.debug('Updating fpt');

  return backendAuthenticatedAxios.patch<BackendUserResponse>(
    '/quote/update-fpt', fptData,
  );
};

export default updateUserFPT;
