import { PortalChangeRequest } from '../../../../types/portalChangeRequest';
import { backendAuthenticatedAxios } from '../..';

import { AxiosResponse } from 'axios';

const updateChangeRequest = async (
  portalChangeRequestId: string, reqBody: Partial<PortalChangeRequest>,
): Promise<AxiosResponse<PortalChangeRequest | null>> => {
  return backendAuthenticatedAxios.patch<PortalChangeRequest>(`/staff/portal-change-request/${portalChangeRequestId}`, reqBody);
};

export default updateChangeRequest;
