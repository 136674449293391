import { Snapshot } from 'recoil';

import Field from './Field';
import Rule from './Rule';
import { Dict, FieldDict, InferFromFieldDict } from './types';
import usePopulate from './usePopulate';
import useReset from './useReset';
import useSubmit from './useSubmit';

/**
 * Manages state associated with a form.
 *
 * @note designed to be agnostic to field types and input components
 */
class Form<Fields extends FieldDict> {
  /**
   * Object containing all Field instances in this Form
   */
  fields: Fields;

  /**
   * Constructs a new Form instance
   *
   * @example
   *  const myForm = new Form({
   *    fields: {
   *      name: new Field<string>({...})
   *      age: new Field<number | null>({...})
   *    }
   *  }
   */
  constructor({ fields }: { fields: Fields }) {
    this.fields = fields;
  }

  /**
   * Returns a snapshot of the Field values in `this.fields`
   *
   * @param snapshot Recoil snapshot object from `useRecoilCallback`
   */
  async getFieldValuesSnapshot(
    snapshot: Snapshot,
  ): Promise<InferFromFieldDict<Fields>> {
    const fieldValues: Dict<unknown> = {};

    for (const fieldKey in this.fields) {
      fieldValues[fieldKey] = await snapshot.getPromise(
        this.fields[fieldKey].valueState,
      );
    }

    return fieldValues as InferFromFieldDict<Fields>;
  }

  // STATIC

  /**
   * Creates a new Form from two other Forms.
   *
   * @note The elements of the fields array
   *  reference the same instances of Field
   *  as in the original forms.
   */
  static join<A extends FieldDict, B extends FieldDict>(
    formA: Form<A>,
    formB: Form<B>,
  ): Form<A & B> {
    return new Form({
      fields: {
        ...formA.fields,
        ...formB.fields,
      },
    });
  }

  static usePopulate = usePopulate;

  static useSubmit = useSubmit;

  static useReset = useReset;
}

export default Form;
export {
  Field,
  Rule,
};
