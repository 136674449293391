import createBreakpoints from '@mui/system/createTheme/createBreakpoints';

import { HEADER_BREAKPOINT } from '../components/layout/ApplyHeader';

const breakpoints = createBreakpoints({});

const stepperOverrides = {
  MuiStep: {
    styleOverrides: {
      horizontal: {
        [breakpoints.down(HEADER_BREAKPOINT + 1)]: {
          paddingLeft: 0,
          paddingRight: 10,
        },
      },
    },
  },
};

export default stepperOverrides;
