import { backendAuthenticatedAxios } from '..';

const validateSignup = async (
  email: string,
): Promise<boolean> => {
  const { data: { valid }} = await backendAuthenticatedAxios.post<ValidateSignupResponse>(
    '/account/validate-signup', { email },
  );

  return valid;
};

interface ValidateSignupResponse {
  valid: boolean;
}

export default validateSignup;
