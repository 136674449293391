const datePickerOverrides = {
  MuiYearPicker: {
    styleOverrides: {
      root: {
        flexDirection: 'column',
      },
    },
  },
};

export default datePickerOverrides;
