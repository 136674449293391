import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { INPUT_DATE_FORMAT, STANDARD_DATE_FORMAT } from '../../../constants/dateConfig';
import backend from '../../../lib/backend';
import { UpdateCommonAppData } from '../../../lib/backend/requests/updateCommonApplicationProfile';
import commonApplicationProfileForm from '../../../state/forms/eslForms/commonApplicationProfileForm';
import { ValidationError } from '../../../types/errors/validation';
import { CommonApplicationProfile } from '../../../types/sponsorshipApplication';

import { format, parse } from 'date-fns';

/**
 * Interface for the return type of the useCommonApplicationForm hook
 */
interface UseCommonApplicationForm {
  submitForm: () => Promise<CommonApplicationProfile | null>;
}

/**
 * Custom hook to manage the state and submission logic for the common application form.
 * Allowing the form to be submitted from any component that uses this hook.
 *
 * @returns An object containing the submit form handler function
 */
const useCommonApplicationForm = (): UseCommonApplicationForm => {
  // Form valid
  const expectedGraduationDateValid = useRecoilValue(commonApplicationProfileForm.fields.expectedGraduationDate.validState);
  const degreeTypeValid = useRecoilValue(commonApplicationProfileForm.fields.degreeType.validState);
  const majorValid = useRecoilValue(commonApplicationProfileForm.fields.major.validState);
  const institutionValid = useRecoilValue(commonApplicationProfileForm.fields.institution.validState);
  const gpaValid = useRecoilValue(commonApplicationProfileForm.fields.current_gpa.validState);

  // Mailing address form valid
  const addressLineValid = useRecoilValue(commonApplicationProfileForm.fields.address_line.validState);
  const addressDetailValid = useRecoilValue(commonApplicationProfileForm.fields.address_detail.validState);
  const cityValid = useRecoilValue(commonApplicationProfileForm.fields.city.validState);
  const stateValid = useRecoilValue(commonApplicationProfileForm.fields.state.validState);
  const zipValid = useRecoilValue(commonApplicationProfileForm.fields.zip.validState);

  const formValid = useMemo(
    () => [ expectedGraduationDateValid, degreeTypeValid, majorValid, institutionValid, gpaValid, addressLineValid, addressDetailValid, cityValid, stateValid, zipValid ].every((fieldValid) => fieldValid),
    [ expectedGraduationDateValid, degreeTypeValid, majorValid, institutionValid, gpaValid, addressLineValid, addressDetailValid, cityValid, stateValid, zipValid ],
  );

  // Form values
  const expectedGraduationDate = useRecoilValue(commonApplicationProfileForm.fields.expectedGraduationDate.valueState);
  const degreeType = useRecoilValue(commonApplicationProfileForm.fields.degreeType.valueState);
  const major = useRecoilValue(commonApplicationProfileForm.fields.major.valueState);
  const institution = useRecoilValue(commonApplicationProfileForm.fields.institution.valueState);
  const gpa = useRecoilValue(commonApplicationProfileForm.fields.current_gpa.valueState);

  // Mailing address form values
  const addressLine = useRecoilValue(commonApplicationProfileForm.fields.address_line.valueState);
  const addressDetail = useRecoilValue(commonApplicationProfileForm.fields.address_detail.valueState);
  const city = useRecoilValue(commonApplicationProfileForm.fields.city.valueState);
  const state = useRecoilValue(commonApplicationProfileForm.fields.state.valueState);
  const zip = useRecoilValue(commonApplicationProfileForm.fields.zip.valueState);

  const submitForm = useCallback(async () => {
    if (!formValid) throw new ValidationError();

    const commonAppData: UpdateCommonAppData = {
      expected_graduation_date: format(
        parse(expectedGraduationDate, INPUT_DATE_FORMAT, new Date()),
        STANDARD_DATE_FORMAT,
      ),
      degree_type: degreeType,
      major: major?.code,
      institution: institution?.id,
      gpa: gpa,
      address_line: addressLine?.trim(),
      address_detail: addressDetail?.trim(),
      city: city?.trim(),
      state: state?.abbreviation as string,
      zip: zip?.trim(),
    };

    const { data: commonApplicationProfile } = await backend.updateCommonApplicationProfile(commonAppData);

    return commonApplicationProfile;
  }, [ addressDetail, addressLine, city, degreeType, expectedGraduationDate, formValid, gpa, institution, major, state?.abbreviation, zip ]);

  return { submitForm };
};

export default useCommonApplicationForm;
