import { FunctionComponent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import { AppBar, Box, Container } from '@mui/material';

import { encouragingBanner } from '../../constants/encouraging';
import useMobileDetect from '../../hooks/useMobileDetect';
import { numberToUsdString } from '../../lib/currency';
import env from '../../lib/env';
import routes from '../../lib/routes';
import universityEligibilityForm from '../../state/forms/universityElegibilityForm/form';
import palette from '../../styles/palette';
import { CustomLink } from '../CustomLink';

import Banner from './Banner';
import ElegibilityProgressBar from './ElegibilityProgressBar';
import TopBar from './TopBar';

export const HEADER_BREAKPOINT = 730;

const minAmount = numberToUsdString(env.var.MIN_FUNDING_AMOUNT);
const maxAmount = numberToUsdString(env.var.MAX_FUNDING_AMOUNT);

const ApplyHeader: FunctionComponent = () => {
  const router = useRouter();
  const isMobile = useMobileDetect(HEADER_BREAKPOINT);

  const isEligibilityPage = router.pathname.startsWith('/eligibility');
  const [ bannerPhrase, setBannerPhrase ] = useState('');

  const fundingAmount = useRecoilValue(
    universityEligibilityForm.fields.fundingAmount.valueState,
  );

  useEffect(() => {
    setBannerPhrase(
      encouragingBanner[
        Math.floor(Math.random() * encouragingBanner.length)
      ],
    );
  }, []);

  return (
    <AppBar color='default' position='sticky'>
      <Container>
        <TopBar
          isMobile={isMobile}
          showProgress={!isMobile}
        />
      </Container>
      {
        isMobile && (
          <Box
            bgcolor='#FBFBFC'
          >
            <ElegibilityProgressBar />
          </Box>
        )
      }
      {
        isEligibilityPage && (
          <Banner
            color='primary'
          >
            {bannerPhrase}
          </Banner>
        )
      }
      {
        (
          typeof fundingAmount === 'number'
        && fundingAmount > env.var.MAX_FUNDING_AMOUNT
        && router.pathname === routes.internal.applyQuote.url
        ) && (
          <Banner
            bgcolor={palette.pink.contrastText}
            width={708}
          >
            Your requested ISL is above <b>{minAmount}</b>.
            For your program Stride can fund between {minAmount} and {maxAmount}.
            You can cover the rest with one of <CustomLink href={routes.internal.partners.url}>our partners</CustomLink>.
          </Banner>
        )
      }
    </AppBar>
  );
};

export default ApplyHeader;
