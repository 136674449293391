import { ChangeRequestState } from '../../../../constants/changeRequestState';
import { backendAuthenticatedAxios } from '../..';

import { AxiosResponse } from 'axios';

export interface ChangeRequestsReview {
  change_request_id: string;
  decision: ChangeRequestState;
}

const handleChangeRequestReview = async (
  changeRequestsReview: ChangeRequestsReview,
): Promise<AxiosResponse<void>> => {
  return backendAuthenticatedAxios.post<void>(
    'staff/change-request/handle-change-request', changeRequestsReview,
  );
};

export default handleChangeRequestReview;
