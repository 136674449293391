import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

export interface FlinksData {
  accountTitle?: string;
  accountType?: string;
  bankAccountId?: string;
  institutionName?: string | number;
  institutionCode?: number;
  lastDatePull?: string;
  loginId?: string;
}

const handleBankEvent = async (
  customerId: string | null,
  flinksData: FlinksData,
): Promise<AxiosResponse<void>> => {
  return backendAuthenticatedAxios.post<void>(
    `/customer/${customerId}/handle-bank-event/`, flinksData,
  );
};

export default handleBankEvent;
