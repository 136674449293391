import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';

import confirmationStatusAtom from '../state/atoms/confirmationStatus';
import {
  ConfirmationStatus,
  DEFAULT_CONFIRMATION_MESSAGES,
} from '../types/confirmations/confirmationStatus';

/**
 * Represents a set of utility functions related to managing confirmations using Recoil state.
 *
 * @typedef {Object} useConfirmationUtils
 * @property {ConfirmationStatus | null} confirmationStatus - The current confirmation status.
 * @property {function(newConfirmationStatus: Partial<ConfirmationStatus>): void} showConfirmationStep - Sets the confirmation status.
 * @property {function(): void} handleDialogClose - Closes the confirmation dialog.
 * @property {function(): void} handleDialogConfirm - Confirms the confirmation dialog and executes the onClick function.
 */

type useConfirmationUtils = {
  confirmationStatus: ConfirmationStatus | null;
  showConfirmationStep: (newConfirmationStatus: Partial<ConfirmationStatus>) => void;
  handleDialogClose: () => void;
  handleDialogConfirm: () => void;
  isCheckboxChecked: boolean;
  setCheckboxChecked: (checked: boolean) => void;
};

/**
 * A custom hook for managing confirmations using Recoil state.
 * @returns {useConfirmationUtils} An object containing utility functions.
 */
const useConfirmation = () : useConfirmationUtils => {
  const [ confirmationStatus, setConfirmationStatus ] = useRecoilState(confirmationStatusAtom);
  const [ isCheckboxChecked, setCheckboxChecked ] = useState(false);

  /**
   * Sets the confirmation status.
   *
   * @param {Partial<ConfirmationStatus>} newConfirmationStatus - The new confirmation status to set.
   */
  const showConfirmationStep = useCallback((newConfirmationStatus: Partial<ConfirmationStatus>) => {
    setConfirmationStatus({
      dialogOpen: true,
      onClick: newConfirmationStatus.onClick ?? (() => {}),
      messages: { ...DEFAULT_CONFIRMATION_MESSAGES, ...(newConfirmationStatus?.messages ?? {}) },
      checkbox: newConfirmationStatus.checkbox,
    });
    setCheckboxChecked(false);
  }, [setConfirmationStatus]);

  /**
   * Closes the confirmation dialog.
   */
  const handleDialogClose = useCallback(() => {
    setConfirmationStatus({ ...confirmationStatus, dialogOpen: false, onClick: () => {} });
  }, [ confirmationStatus, setConfirmationStatus ]);

  /**
   * Confirms the confirmation dialog.
   */
  const handleDialogConfirm = useCallback(() => {
    confirmationStatus.onClick();
    setConfirmationStatus({ ...confirmationStatus, dialogOpen: false, onClick: () => {} });
  }, [ confirmationStatus, setConfirmationStatus ]);

  return { showConfirmationStep, handleDialogClose, handleDialogConfirm, confirmationStatus, isCheckboxChecked, setCheckboxChecked };
};

export default useConfirmation;
