import { DepositAmounts } from '../../../types/Repayment';
import { RepaymentFund } from '../../../types/responseSchema/repaymentPartner';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

type SubmitRepaymentFundVerificationProps = {
  repaymentFund: RepaymentFund;
  depositAmounts: DepositAmounts;
};

const submitRepaymentFundVerification = async ({
  repaymentFund,
  depositAmounts,
}: SubmitRepaymentFundVerificationProps): Promise<AxiosResponse<void>> => {
  const requestData = {
    'partner_id': repaymentFund.partner_id,
    'deposits': [
      depositAmounts.amount1,
      depositAmounts.amount2,
    ],
  };

  return await backendAuthenticatedAxios
    .post('/spinwheel/employer/verify-payer-account', requestData);
};

export default submitRepaymentFundVerification;
