import { CallbackInterface, useRecoilCallback } from 'recoil';

import { FieldDict, InferFromFieldDict, InferFromRecoilState } from './types';

import Form from '.';

/**
 * Returns a memoized callback function
 *  that populates a `Form` instance with given data.
 *
 * @param form
 *  The `Form` instance that will be modified
 * @param newData
 *  Object with properties that represent
 *    form fields and their new values
 *
 * @example
 *  const populate = Form.usePopulate(myForm, {
 *    name: 'Luke Skywalker',
 *  });
 */
const usePopulate = <Fields extends FieldDict>(
  form: Form<Fields>,
  newData: InferFromFieldDict<Fields>,
): () => Promise<void> =>
    useRecoilCallback(({ set }: CallbackInterface) => async () => {
      for (const fieldKey in newData) {
        const currentFieldValueState = form.fields[fieldKey].valueState;

        // Extract generic type from currentValueState
        type CurrentFieldValueType = InferFromRecoilState<typeof currentFieldValueState>;

        // Set the value of current field
        set<CurrentFieldValueType>(
          currentFieldValueState,
          newData[fieldKey],
        );
      }
    });

export default usePopulate;
