import { ExternalLinkModalText, externalLinkModalText }  from '../../constants/externalLinkModalText';
import { sessionAtom } from '../../lib/recoil/persist';

// Data type used by the atom
export type ExternalLinkModalState = {
    isExternalLinkModalOpen: boolean;
    externalLinkModalText: ExternalLinkModalText;
    onContinueUrl: string;
};

// Default value of the atom
const defaultExternalLinkModalText : ExternalLinkModalState = {
  isExternalLinkModalOpen: false,
  externalLinkModalText: externalLinkModalText.default,
  onContinueUrl: '',
};

// Atom used to store the state of the ExternalLinkModal
const externalLinkModalState = sessionAtom<ExternalLinkModalState>({
  key: 'externalLinkModalState',
  default: defaultExternalLinkModalText,
});

export {
  defaultExternalLinkModalText,
  externalLinkModalState,
};
