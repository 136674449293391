/**
 * Parses the given environment variable using the given `constructor`
 *
 * @param envKey Key of the env var -- used only for debugging
 * @param envValue Value of the env var in the form `process.env.ENV_KEY`
 * @param fallback Value used if `envValue` is missing
 *
 * @note Parsing without a `fallback` will throw an error
 *  when variable is missing from the environment
 */
const parseEnv = <T extends unknown>(
  envKey: string,
  envValue: string | undefined,
  fallback?: T,
  constructor?: (envValue: string) => T,
): T => {
  if (envValue === undefined || envValue === '') {
    if (fallback === undefined)
      throw new Error(`Missing environment variable: ${envKey} -- no fallback given`);
    console.warn(`Missing environment variable: ${envKey} -- using fallback`);

    return fallback;
  } else
    return typeof constructor === 'function'
      ? constructor(envValue)
      : envValue as T;
};

export default parseEnv;
