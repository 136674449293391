import { CustomerRepaymentProduct } from '../../../state/user/types';
import { RepaymentFund } from '../../../types/responseSchema/repaymentPartner';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

type GetCustomerRepaymentProductsProps = {
  repaymentFund: RepaymentFund;
};

export interface GetCustomerRepaymentProductsResponse {
  raw_response: CustomerRepaymentProduct[];
}

const getCustomerRepaymentProducts = async ({
  repaymentFund,
}: GetCustomerRepaymentProductsProps): Promise<AxiosResponse<GetCustomerRepaymentProductsResponse>> => {
  const requestData = {
    'partner_id': repaymentFund.partner_id,
  };

  return await backendAuthenticatedAxios
    .post('/repayment/employer/customer_repayment_products/get', requestData);
};

export default getCustomerRepaymentProducts;
