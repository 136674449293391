import { backendAuthenticatedAxios } from '..';
import { BackendUserResponse } from '../types';

import { AxiosResponse } from 'axios';

interface UpdateUserData {
  first_name?: string;
  last_name?: string;
  mobile_number?: string;
  name?: string;
}

const updateUser = async (
  userData: UpdateUserData,
): Promise<AxiosResponse<BackendUserResponse>> => {
  console.debug('Updating user');
  console.debug(userData);

  return backendAuthenticatedAxios.patch<BackendUserResponse>(
    '/account/me', userData,
  );
};

export default updateUser;
