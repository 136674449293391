/**
 * DIM errors definitions
 */

/**
 * Custom type definition for DIM errors
 */
export type DIMError = {
    eventName: string;
    message: string;
    metadata?: any;
};

export const DIM_FALLBACK_ERROR_MESSAGE = 'We are experiencing issues reaching our student loan connector. Please contact us at support@stridefunding or (214) 775-9960.'; // Generic error message
