import { Disbursement } from '../../../../types/disbursement';
import { backendAuthenticatedAxios } from '../..';

export type GenerateFinwisePayoffError = {
  'detail': string;
};

type GenerateFinwisePayoffResponse = Disbursement[] | GenerateFinwisePayoffError;

const generateFinwisePayoffs = async (applicationIds: string[]) => {
  const reqBody = {
    'originator_app_ids': applicationIds,
  };

  return backendAuthenticatedAxios.post<GenerateFinwisePayoffResponse>('/staff/disbursement/generate-finwise-payoff', reqBody);
};

export default generateFinwisePayoffs;
