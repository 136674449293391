import { BorrowerAccountProfile } from '../../../types/borrowerAccountProfile';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

/**
 * Retrieves a list of borrower account profiles.
 *
 * This method fetches borrower account profiles from the backend, which are used to populate the frontend with relevant account information.
 *
 * @returns A promise that resolves to an AxiosResponse containing an array of BorrowerAccountProfile objects.
 */
const getBorrowerAccountProfiles = async ():
    Promise<AxiosResponse<BorrowerAccountProfile[]>> => backendAuthenticatedAxios.get('repayment/account/borrower-accounts-profiles');

export default getBorrowerAccountProfiles;
