const cardOverrides = {
  MuiPaper: {
    styleOverrides: {
      root: {
        '&.MuiCard-root': {
          boxShadow: 'none',
        },
      },
    },
  },
};

export default cardOverrides;
