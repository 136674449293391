import React, { FunctionComponent } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { BorrowerAccountProfile } from '../../../types/borrowerAccountProfile';

type ConfirmAccountNumberDialogProps = {
  loanServicerAccount: BorrowerAccountProfile | undefined;
  onManualUpdate: () => void;
  onConfirm: () => Promise<void>;
  onClose: () => void;
};

export const ConfirmAccountNumberDialog: FunctionComponent<ConfirmAccountNumberDialogProps> = ({ loanServicerAccount, onManualUpdate, onConfirm, onClose }) => {
  return (
    <Dialog
      onClose={onClose}
      open={true}
    >
      <Box id='confirm-account-number-dialog' sx={{ px: 3, py: 2 }}>
        <DialogTitle>Please confirm your account number</DialogTitle>
        <DialogContent>
          You can verify your account number by checking a monthly statement from your servicer.
          Please ensure the account number below matches the one on your statement.
          <Typography mt={3} variant='h5'><b>Account Number:</b> {loanServicerAccount?.account_number}</Typography>
        </DialogContent>
        <DialogActions>
          <Box display='flex' justifyContent='space-evenly' width='100%'>
            <Button id='confirm-account-number-dialog-manual-update-action' onClick={onManualUpdate} size='small' sx={{ m: 1, border: '1px solid', borderColor: 'primary.main', color: 'primary.main' }} variant='outlined'>
              No, these do not match
            </Button>
            <Button autoFocus id='confirm-account-number-dialog-confirm-action' onClick={onConfirm} size='small' sx={{ m: 1 }} variant='contained'>
              Yes, looks good!
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
