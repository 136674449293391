import { ChangeRequest } from '../../../../types/changeRequest';
import { backendAuthenticatedAxios } from '../..';
import { BulkActionResponse } from '../../types';

import { AxiosResponse } from 'axios';

type SyncFinwiseLoanTransactionResponse = BulkActionResponse<ChangeRequest>;

const syncFinwiseLoanTransactions = async (applicationIds: string[]): Promise<AxiosResponse<SyncFinwiseLoanTransactionResponse>> => {
  const reqBody = { 'originator_app_ids': applicationIds };

  // TODO - error handling/alerting
  // TODO: SSP-2440: This should take individual PCR IDs rather than an app ID
  return await backendAuthenticatedAxios.post<SyncFinwiseLoanTransactionResponse>('/staff/portal-change-request/sync-loan-transaction', reqBody)
    .catch(() => {
      console.log('Error occured during FWB sync');

      throw new Error('Failed to Sync Loan Transaction Objects');
    });
};

export default syncFinwiseLoanTransactions;
