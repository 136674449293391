import { BorrowerAccountProfile } from '../../../types/borrowerAccountProfile';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const confirmAccountNumber = async (loanServicerAccountId: string): Promise<AxiosResponse<BorrowerAccountProfile>> => {
  return backendAuthenticatedAxios.patch<BorrowerAccountProfile>(
    `repayment/account/${loanServicerAccountId}/confirm-account-number`,
  );
};

export default confirmAccountNumber;
