
import { PortalChangeRequestListing } from '../../../../types/portalChangeRequest';
import { backendAuthenticatedAxios } from '../..';

import { AxiosResponse } from 'axios';

export const getPartnerPortalChangeRequests = async (): Promise<AxiosResponse<PortalChangeRequestListing>> => {
  return backendAuthenticatedAxios.get<PortalChangeRequestListing>('/partner/change-requests');
};

export default getPartnerPortalChangeRequests;
