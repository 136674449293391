import { useMediaQuery } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';

const breakpoints = createBreakpoints({});

const useMobileDetect = (breakpoint: number | Breakpoint = 'sm'): boolean => {
  return useMediaQuery(`(max-width: ${
    'string' === typeof breakpoint
      ? breakpoints.values[breakpoint]
      : breakpoint
  }px)`);
};

export default useMobileDetect;
