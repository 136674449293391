
import { CustomerFundingProduct } from '../../../../state/user/types';
import { backendAuthenticatedAxios } from '../..';
import { getFiltersQueryParams, getOrderingQueryParams, getPaginationQueryParams } from '../../pagination';
import { PaginatedResponse } from '../../types';

import { AxiosResponse } from 'axios';
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from 'material-react-table';

type GetCustomerFundingProductsResponse = PaginatedResponse<CustomerFundingProduct>;

const getCustomerFundingProducts = async (
  applicationIdList: string[],
  pagination: MRT_PaginationState,
  columnFilters?: MRT_ColumnFiltersState,
  sorting?: MRT_SortingState,
): Promise<AxiosResponse<GetCustomerFundingProductsResponse>> => {
  /**
   * Backend request to get Customer Funding Products using a list of App Ids (Staff use only).
   */

  // Relative URL
  const relativeUrl = '/staff/cfp/get-by-ids';

  // Query parameters
  const paginationOptions = getPaginationQueryParams(pagination);
  const orderingOptions = getOrderingQueryParams(sorting);
  const filterOptions = getFiltersQueryParams(columnFilters);

  const queryParams = new URLSearchParams({
    ...paginationOptions,
    ...orderingOptions,
    ...filterOptions,
    'originator_app_id_in': applicationIdList.join(','),
  });

  // Encode Partner name to account for spaces and reduce transformations across interfaces
  return await backendAuthenticatedAxios.get<GetCustomerFundingProductsResponse>(`${relativeUrl}?${queryParams.toString()}`)
    .catch(() => {
      throw new Error('No Customer Funding Products Found');
    });
};

export default getCustomerFundingProducts;
