import { ChangeRequest } from '../../../../types/changeRequest';
import { backendAuthenticatedAxios } from '../..';

import { AxiosResponse } from 'axios';

const getChangeRequests = async (applicationIdList: string[]): Promise<AxiosResponse<ChangeRequest[] | null>> => {
  const reqBody = {
    'search_terms': applicationIdList,
  };

  return backendAuthenticatedAxios.post<ChangeRequest[]>('/staff/change-request/get-by-ids', reqBody);
};

export default getChangeRequests;
