import { backendAuthenticatedAxios } from '../..';

const approveFinwiseLoans = async (applicationIds: string[]): Promise<void> => {
  const reqBody = {
    'originator_app_ids': applicationIds,
  };

  // TODO - Better error handling?
  await backendAuthenticatedAxios.post<void>('/staff/cfp/approve-finwise-loan', reqBody)
    .catch(() => {
      throw new Error('Failed to Approve Loan');
    });
};

export default approveFinwiseLoans;
