import palette from './palette';

const drawerStyles = {
  width: 210,
  boxShadow: '4px 0px 20px rgba(5, 30, 39, 0.05)',
};

const sidebarOverrides = {
  MuiDrawer: {
    styleOverrides: {
      paperAnchorDockedLeft: drawerStyles,
      paperAnchorLeft: drawerStyles,
    },
  },
  MuiListItem: {
    styleOverrides: {
      gutters: {
        paddingLeft: 0,
      },
      root: {
        color: palette.text.secondary,
        '&$selected': {
          color: palette.text.primary,
          backgroundColor: palette.primary.light,
          borderLeftWidth: 3,
          borderLeftColor: palette.purple.bright,
          borderLeftStyle: 'solid',
        },
        '&:hover': {
          color: palette.text.primary,
          backgroundColor: palette.primary.light,
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 60,
        marginTop: 2,
      },
    },
  },
};

export default sidebarOverrides;
