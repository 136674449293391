const encouragingBanner = [
  'Pay for school with your future earnings.',
  'Get a quote in less than 1 minute.',
  'No payments when you’re not earning.',
  'How paying for school should feel.',
  'Not your parents’ student loans.',
  'We never require a cosigner.',
  'We’re focused on your future.',
];

const whyStride = [
  'Get a quote in less than 1 minute.',
  'How paying for school should feel.',
  'Not your parents’ student loans.',
  'No payments when you’re not earning.',
  'We never require a cosigner.',
];

export { encouragingBanner, whyStride };
