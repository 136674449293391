import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import useAlert from '../../../hooks/useAlert';
import backend from '../../../lib/backend';
import { sponsorshipAgreementActiveModal } from '../../../state/atoms/sponsorshipAgreementLoadingModalOpenState';
import { useUser } from '../../../state/user';

const HangTightModal: FunctionComponent = () => {
  const setSponsorshipAgreementActiveModal = useSetRecoilState(sponsorshipAgreementActiveModal);
  const router = useRouter();
  const user = useUser();
  const { showErrorAlert } = useAlert();

  const latestSponsorshipApplication = user.contents.latestSponsorshipApplication;

  const fetchSponsorshipApplicationAgreementSignLink = useCallback(async () => {
    const sponsorshipApplicationId = latestSponsorshipApplication.id;

    try {
      const { data: { sign_url: signUrl }} = await backend.getSponsorshipAgreementSignLink(sponsorshipApplicationId);
      await router.push(signUrl); // Navigate to the sign URL
    } catch (err) {
      setSponsorshipAgreementActiveModal(null); // Reset modal if an error occurs
      showErrorAlert('An error occurred while generating the sponsorship agreement. Please try again.');
      console.error(err);
    }
  }, [ latestSponsorshipApplication?.id, router, setSponsorshipAgreementActiveModal, showErrorAlert ]);

  useEffect(() => {
    if (user.isLoaded) fetchSponsorshipApplicationAgreementSignLink();
  }, [ fetchSponsorshipApplicationAgreementSignLink, user.isLoaded ]);

  return (
    <Stack p='3rem' spacing='3rem'>
      <Typography variant='h2'>Hold tight!</Typography>
      <Typography>Please do not refresh or close this page until after you’ve reviewed your sponsorship agreement. Once signed, it might take a few minutes for the document to be ready for viewing.</Typography>
      <Box alignItems='center' display='flex' justifyContent='center'>
        <CircularProgress />
      </Box>
    </Stack>
  );
};

export default HangTightModal;
