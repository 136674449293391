const numberToUsdString = (input: number | null, maximumFractionDigits = 0): string => {
  return input === 0
    ? '$0'
    : !input
      ? ''
      : input.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: maximumFractionDigits,
      });
};

const usdStringToNumber = (input: string): number => parseFloat(
  input.replace(/[^0-9.-]+/g, ''),
);

export { numberToUsdString, usdStringToNumber };
