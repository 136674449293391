/**
 * @note: Source = https://docs.flinks.com/docs/events
 */
export type EventSteps = 'COMPONENT_CONSENT_INTRO'
                        | 'INSTITUTION_SELECTED'
                        | 'REDIRECT';

export const eventSteps: Record<string, EventSteps> = {
  componentLoadConsentIntro: 'COMPONENT_CONSENT_INTRO',
  institutionSelected: 'INSTITUTION_SELECTED',
  redirect: 'REDIRECT',
};

export type FlinksEvent = {
  institution?: string | number;
  loginId?: string;
  step?: string;
};
