import { CustomerRepaymentProduct } from '../../../state/user/types';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

/**
 * Update customer repayment product connection status to COMPLETED
 */
const updateRepaymentProductConnectionToCompleted = async (
  customerRepaymentProductId: string,
): Promise<AxiosResponse<CustomerRepaymentProduct>> => {
  return backendAuthenticatedAxios.patch(
    `/repayment/crp/${customerRepaymentProductId}/connection-completed`,
  );
};

export default updateRepaymentProductConnectionToCompleted;
