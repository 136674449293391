import { RepaymentPartner } from '../../../types/responseSchema/repaymentPartner';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

type RepaymentPartnerResponse = {
  data: RepaymentPartner[];
};

/**
 * Method to retrieve repayment partner list
 *
 * @returns repayment partner list, used to power the FE
 */
const getRepaymentPartnerList = async ():
Promise<AxiosResponse<RepaymentPartnerResponse>> => {
  return await backendAuthenticatedAxios.get('repayment/employer/get');
};

export default getRepaymentPartnerList;
