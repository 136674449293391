import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Grid, Typography } from '@mui/material';

import useAlert from '../../../hooks/useAlert';
import useConfirmation from '../../../hooks/useConfirmation';
import backend from '../../../lib/backend';
import confirmAccountNumber from '../../../lib/backend/requests/confirmAccountNumber';
import updateAccountNumber from '../../../lib/backend/requests/updateAccountNumber';
import { borrowerAccountProfilesAtom } from '../../../state/atoms/borrowerAccountProfiles';
import spinwheelActiveModal from '../../../state/atoms/spinwheelActiveModal';
import userStateNeedsReset from '../../../state/atoms/userStateNeedsReset';
import { BorrowerAccountProfile, BorrowerAccountProfileConnectionStatus } from '../../../types/borrowerAccountProfile';
import { DEFAULT_CONFIRMATION_MESSAGES } from '../../../types/confirmations/confirmationStatus';
import { ACCOUNT_NUMBER_MODALS } from '../../../types/spinwheelModals';
import LoanServicerAccountCard from '../../spinwheel/LoanServicerAccountCard';

import { ConfirmAccountNumberDialog } from './ConfirmAccountNumberDialog';
import { UpdateAccountNumberDialog } from './UpdateAccountNumberDialog';

const SelectLoanServicerAccount: FunctionComponent = () => {
  const setActiveModal = useSetRecoilState(spinwheelActiveModal);
  const setUserStateResetNeeded = useSetRecoilState(userStateNeedsReset);

  const [ borrowerAccounts, setAccounts ] = useRecoilState(borrowerAccountProfilesAtom);
  const accounts = useMemo(() => borrowerAccounts ?? [], [borrowerAccounts]);
  const [ connectedAccount, setConnectedAccount ] = useState(accounts.find((account) => account.connection_status === BorrowerAccountProfileConnectionStatus.CONNECTED) ?? null);
  const [ selectedAccount, setSelectedAccount ] = useState<BorrowerAccountProfile | null>(null);

  const [ activeDialog, setActiveDialog ] = useState<ACCOUNT_NUMBER_MODALS | null>(null);

  const { showSuccessAlert, showErrorAlert } = useAlert();
  const { showConfirmationStep } = useConfirmation();

  const onSuccessfulConnect = useCallback(() => setActiveModal(null), [setActiveModal]);

  const fetchAccounts = useCallback(async () => {
    if (!accounts.length) {
      try {
        const response = await backend.getBorrowerAccountProfiles();
        setAccounts(response.data);
      } catch (error) {
        console.error('Failed to fetch borrower accounts:', error);
      }
    }
  }, [ accounts.length, setAccounts ]);

  const updateAccountStatus = useCallback(async (accountId: string, status: BorrowerAccountProfileConnectionStatus, message: string)   => {
    try {
      const updatedAccounts = await backend.submitBorrowerAccountProfileConnection({ borrowerAccountProfileId: accountId, status });
      const newConnectedAccount = updatedAccounts?.data?.find((account): account is BorrowerAccountProfile => account.id === accountId) ?? null;

      // reset user state
      setUserStateResetNeeded(true);

      setConnectedAccount(newConnectedAccount);
      setAccounts(updatedAccounts?.data);
      if (status === BorrowerAccountProfileConnectionStatus.CONNECTED) onSuccessfulConnect();
      showSuccessAlert(message);
    } catch (err) {
      showErrorAlert(`An error occurred while updating the account status: ${err}`);
    }
  }, [ setUserStateResetNeeded, setAccounts, onSuccessfulConnect, showSuccessAlert, showErrorAlert ]);

  const connectAccount = useCallback(async (accountId: string) => {
    updateAccountStatus(accountId, BorrowerAccountProfileConnectionStatus.CONNECTED, 'Connection successful!');
  }, [updateAccountStatus]);

  const connectAccountWithChecks = useCallback(async (accountId: string) => {
    const selectedAccount = accounts.find((account) => account.id === accountId);

    if (!selectedAccount) {
      console.error('Provided account id is not present between available options.');

      return;
    }

    setSelectedAccount(selectedAccount);

    if (!selectedAccount?.account_number) {
      setActiveDialog(ACCOUNT_NUMBER_MODALS.UPDATE);

      return;
    }

    if (!selectedAccount.account_number_verified_on) {
      setActiveDialog(ACCOUNT_NUMBER_MODALS.CONFIRM);

      return;
    }

    await connectAccount(accountId);
  }, [ accounts, connectAccount, setActiveDialog, setSelectedAccount ]);

  const onConnectionChange = useCallback(async ({ accountId, connectionStatus }: { accountId: string; connectionStatus: BorrowerAccountProfileConnectionStatus }) => {
    try {
      if (connectedAccount && connectionStatus === BorrowerAccountProfileConnectionStatus.DISCONNECTED) {
        await updateAccountStatus(accountId, BorrowerAccountProfileConnectionStatus.DISCONNECTED, 'Account disconnected!');

        return;
      }

      if (connectionStatus === BorrowerAccountProfileConnectionStatus.CONNECTED) {
        await connectAccountWithChecks(accountId);

        return;
      }
    } catch (err) {
      showErrorAlert('An error occurred while changing your connected account.');
      console.error(err);
    }
  }, [ connectedAccount, updateAccountStatus, connectAccountWithChecks, showErrorAlert ]);

  const onUpdate = ({ id, connectionStatus }: { id: string; connectionStatus: BorrowerAccountProfileConnectionStatus }) => {
    if (connectionStatus === BorrowerAccountProfileConnectionStatus.DISCONNECTED) {
      showConfirmationStep({
        onClick: () => onConnectionChange({ accountId: id, connectionStatus }),
        messages: {
          ...DEFAULT_CONFIRMATION_MESSAGES,
          description: 'A connected student loan account is required to facilitate the repayment benefits outlined in your sponsorship agreement.',
        },
      });
    } else {
      onConnectionChange({ accountId: id, connectionStatus });
    }
  };

  const onAccountNumberUpdate = async (newAccountNumber: string) => {
    try {
      await updateAccountNumber(selectedAccount?.id as string, newAccountNumber);
      await connectAccount(selectedAccount?.id as string);
    } catch (err: any) {
      // Cannot use isCustomError - non standard error format (no detail in data)
      if (err?.response && err?.response?.data) {
        showErrorAlert(`Unsuccessful account number update. Details: ${err}`);
      } else {
        showErrorAlert('Unknown error occurred.');
      }
    } finally {
      setActiveDialog(null);
    }
  };

  const onAccountNumberConfirm = async () => {
    try {
      await confirmAccountNumber(selectedAccount?.id as string);
      await connectAccount(selectedAccount?.id as string);
    } catch (err: any) {
      // Cannot use isCustomError - non standard error format (no detail in data)
      if (err?.response && err?.response?.data) {
        showErrorAlert(`Unsuccessful account number confirmation. Details: ${err}`);
      } else {
        showErrorAlert('Unknown error occurred.');
      }
    } finally {
      setActiveDialog(null);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  useEffect(() => {
    if (accounts.length > 0) {
      const connected = accounts.find((account) => account.connection_status === BorrowerAccountProfileConnectionStatus.CONNECTED) ?? null;
      setConnectedAccount(connected);
    }
  }, [accounts]);

  return (
    <>
      <Grid alignItems={'center'} container data-cy='loan-servicer-choice-text' direction={'column'} px={3} py={3} sx={{ backgroundColor: '#F5F5F5', borderRadius: '12px' }}>
        <Grid container item pb={4}>
          <Typography color={'primary'} fontWeight={400} px={8} variant='h2'>
            {
              !connectedAccount
                ? 'Choose your student loan servicer account for employer repayment'
                : 'Update or remove your student loan servicer account for employer repayment'
            }
          </Typography>
        </Grid>
        <Grid container direction={'column'} item rowGap={5}>
          {
            accounts.map((borrowerAccount) => (
              <LoanServicerAccountCard
                borrowerAccount={borrowerAccount}
                key={borrowerAccount.servicer_display_name}
                onUpdate={onUpdate}
              />
            ))
          }
        </Grid>
      </Grid>
      {
        activeDialog === ACCOUNT_NUMBER_MODALS.UPDATE && (
          <UpdateAccountNumberDialog
            onClose={() => setActiveDialog(null)}
            onConfirm={onAccountNumberUpdate}
          />
        )
      }
      {
        activeDialog === ACCOUNT_NUMBER_MODALS.CONFIRM && (
          <ConfirmAccountNumberDialog
            loanServicerAccount={selectedAccount as BorrowerAccountProfile}
            onClose={() => setActiveDialog(null)}
            onConfirm={onAccountNumberConfirm}
            onManualUpdate={() => setActiveDialog(ACCOUNT_NUMBER_MODALS.UPDATE)}
          />
        )
      }
    </>
  );
};

export default SelectLoanServicerAccount;
