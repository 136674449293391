import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const updatePassword = async (id: string, password: string, token: string): Promise<AxiosResponse<void>> => {
  console.log('Updating password');

  return backendAuthenticatedAxios.patch<void>(
    '/account/edit-password',
    {
      id,
      password,
      token,
    },
  );
};

export default updatePassword;
