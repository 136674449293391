import { backendAuthenticatedAxios } from '../..';

export type SyncFinwisePayoffError = {
  'Originator App ID': string;
  'Error': string;
};

export type SyncFinwisePayoffSuccess = {
  'messages': string[];
  'record_key': string;
  'status': string;
  'status_code': string;
};

type SyncFinwisePayoffsResponse = (SyncFinwisePayoffSuccess | SyncFinwisePayoffError)[];

const syncFinwisePayoffs = async (disbursementIds: string[]) => {
  const reqBody = { 'disbursement_ids': disbursementIds };

  return backendAuthenticatedAxios.post<SyncFinwisePayoffsResponse>(
    '/staff/disbursement/sync-finwise-payoff',
    reqBody,
  );
};

export default syncFinwisePayoffs;
