import parseEnv from './parseEnv';

/**
 * Parses the given environment variable as a `boolean`
 *
 * @param envKey Key of the env var -- used only for debugging
 * @param envValue Value of the env var in the form `process.env.ENV_KEY`
 * @param fallback Value used if `envValue` is missing
 *
 * @note Parsing without a `fallback` will throw an error
 *  when variable is missing from the environment
 */
const parseEnvBoolean = (
  envKey: string,
  envValue: string | undefined,
  fallback?: boolean,
): boolean => {
  return parseEnv(envKey, envValue, fallback, (envValue) =>
    [
      '1',
      'on',
      'true',
      'yes',
    ].some((truthyString) => envValue.toLowerCase() === truthyString),
  );
};

export default parseEnvBoolean;

