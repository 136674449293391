import { CustomerFundingProduct } from '../../../../state/user/types';
import { backendAuthenticatedAxios } from '../..';
import { BulkActionResponse } from '../../types';

import { AxiosResponse } from 'axios';

type CancelFinwiseLoans = BulkActionResponse<CustomerFundingProduct>;

const cancelFinwiseLoans = async (applicationIds: string[]): Promise<AxiosResponse<CancelFinwiseLoans>> => {
  const reqBody = {
    'originator_app_ids': applicationIds,
  };

  return backendAuthenticatedAxios.post<CancelFinwiseLoans>('/staff/cfp/cancel-finwise-loans', reqBody);
};

export default cancelFinwiseLoans;
