import { backendAuthenticatedAxios } from '..';
import { BackendUserResponse } from '../types';

import { AxiosResponse } from 'axios';

const verifyResetToken = async (id: string, token: string): Promise<AxiosResponse<BackendUserResponse>> => {
  console.log('Verifying reset token');

  return backendAuthenticatedAxios.get<BackendUserResponse>(
    '/account/verify-reset-token',
    {
      params: {
        'id': id,
        'token': token,
      },
    },
  );
};

export default verifyResetToken;
