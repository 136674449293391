import { CallbackInterface, useRecoilCallback } from 'recoil';

import { FieldDict, InferFromRecoilState } from './types';

import Form from '.';

/**
 * Returns a memoized function that resets
 *  data in the given `Form` instance.
 *
 * @param form
 *  `Form` instance to be resets
 *
 * @example
 *
 * const resetNewChangeRequestForm = Form.useReset(newChangeRequestForm);
 *
 * ...
 *
 * resetNewChangeRequestForm();
 *
 *
 */
const useReset = <Fields extends FieldDict>(
  form: Form<Fields>,
): () => Promise<void> =>
    useRecoilCallback(({ set }: CallbackInterface) => async () => {
      for (const fieldKey in form.fields) {
        const currentFieldValueState = form.fields[fieldKey].valueState;

        // Extract generic type from currentValueState
        type CurrentFieldValueType = InferFromRecoilState<typeof currentFieldValueState>;

        // Reset the value of current field
        set<CurrentFieldValueType>(
          currentFieldValueState,
          form.fields[fieldKey].defaultValue,
        );

        // Reset the value of current field (Not working :/)
        set<boolean>(
          form.fields[fieldKey].touchedState,
          false,
        );
      }
    });

export default useReset;
