import jwt from '../auth/jwt';

/**
 * Signs out the user
 *  by clearing JWT tokens
 */
const signOut = (): void => {
  jwt.clear();
};

export default signOut;
