/**
 * Helper function to open links in a new tab.
 * @param url string URL to open
 *
 */
const openInNewTab = (url : string) : void => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export { openInNewTab };
