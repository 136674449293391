import parseEnv from './parseEnv';

/**
 * Parses the given environment variable as a `string`
 *
 * @param envKey Key of the env var -- used only for debugging
 * @param envValue Value of the env var in the form `process.env.ENV_KEY`
 * @param fallback Value used if `envValue` is missing
 *
 * @note Parsing without a `fallback` will throw an error
 *  when variable is missing from the environment
 */
const parseEnvString = <T extends string>(
  envKey: string,
  envValue: T | undefined,
  fallback?: T,
): T => {
  return parseEnv<T>(envKey, envValue, fallback);
};

export default parseEnvString;
