import { useEffect, useState } from 'react';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';

import Field from '../lib/recoil/Form/Field';
import { HelperContent } from '../lib/recoil/Form/types';

type ShrinkFieldState<T> = {
  value: T;
  setValue: SetterOrUpdater<T>;
  error: boolean;
  helperText: HelperContent;
  touched: boolean;
  shrink: boolean;
  setFocused: SetterOrUpdater<boolean | undefined>;
};

const useShrinkFieldState = <T>(field: Field<T>, defaultValue: T | null = null): ShrinkFieldState<T> => {
  const [ value, setValue ] = useRecoilState(field.valueState);
  const error = useRecoilValue(field.errorState);
  const helperText = useRecoilValue(field.helperTextState);
  const [ touched, setTouched ] = useRecoilState(field.touchedState);
  const [ shrink, setShrink ] = useState(false);
  const [ focused, setFocused ] = useState<boolean>();

  useEffect(() => {
    // Set initial value if it's not touched (page refreshed)
    if (!touched && defaultValue !== null) {
      setValue(defaultValue);
    }

    if (!touched && focused === true) {
      setTouched(true);
    }
    setShrink(!!value || !!focused);
  }, [ focused, touched, setTouched, value, setValue, defaultValue ]);

  return {
    value,
    setValue,
    error,
    helperText,
    touched,
    shrink,
    setFocused,
  };
};

export default useShrinkFieldState;
