import { FunctionComponent } from 'react';
import { CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material';

import theme from '../../styles/theme';

const fullHeightStyles = {
  padding: 0,
  margin: 0,
  height: '100%',
  minHeight: '100%',
};

const ThemeWrapper: FunctionComponent = ({
  children,
}) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={
          {
            html: {
              ...fullHeightStyles,
              '& > body': fullHeightStyles,
              '& > body > #__next': fullHeightStyles,
            },
          }
        }
      />
      {children}
    </ThemeProvider>
  </StyledEngineProvider>
);

export default ThemeWrapper;
