import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';

interface BannerProps {
  bgcolor?: string;
  color?: string;
  width?: number | 'auto';
}

const Banner: FunctionComponent<BannerProps> = ({
  children,
  bgcolor = 'grey.A800',
  color = 'textPrimary',
  width = 'auto',
}) => (
  <Box
    bgcolor={bgcolor}
    pb={0.75}
    pt={0.625}
    textAlign='center'
  >
    <Box
      mx='auto'
      width={width}
    >
      <Typography
        color={color}
        variant='subtitle1'
      >
        {children}
      </Typography>
    </Box>
  </Box>
);

export default Banner;
