/**
 * DIM events definitions
 */
import { DIM_FALLBACK_ERROR_MESSAGE } from './dimErrors';

export enum DIM_EVENT {
    // Loan connection events
    LOAN_CONNECTION_SUCCESS = 'LOAN_CONNECTION_SUCCESS',
    // Identity connection events
    IDENTITY_CONNECTED = 'IDENTITY_CONNECTED',
    // ... add any other event enums
}

export type DIMEvent = {
    eventName: DIM_ERROR_EVENTS | string;
    message: string;
    metadata?: any;
};

/**
 * DIM Error event definitions, for reference: https://docs.spinwheel.io/docs/dim-api#errors
 * Not all are used but placed in the code for reference and possible future usages
 */

export enum DIM_ERROR_EVENTS {
    DROPIN_CONTAINER_NOT_PASSED = 'DROPIN_CONTAINER_NOT_PASSED',
    DROPIN_CONTAINER_UNAVAIL = 'DROPIN_CONTAINER_UNAVAIL',
    DROPIN_TOKEN_UNAVAIL = 'DROPIN_TOKEN_UNAVAIL',
    DROPIN_LOAD_FAIL = 'DROPIN_LOAD_FAIL',
    PAYMENT_FAIL = 'PAYMENT_FAIL',
    SUBSCRIPTION_FAIL = 'SUBSCRIPTION_FAIL',
    API_TIMEOUT = 'API_TIMEOUT',
    OPEN_METHOD_DUPLICATE_CALL = 'OPEN_METHOD_DUPLICATE_CALL',
    EXIT_CALLBACK_UNAVAIL = 'EXIT_CALLBACK_UNAVAIL',
    SUCCESS_CALLBACK_UNAVAIL = 'SUCCESS_CALLBACK_UNAVAIL',
    DIM_UNAVAILABLE_OUTAGE = 'DIM_UNAVAILABLE_OUTAGE',
    INVALID_MODULE_NAME = 'INVALID_MODULE_NAME',
    DIM_ERROR = 'DIM_ERROR', // Generic error definition
    INVALID_TOKEN = 'INVALID_TOKEN',
    EXPIRED_TOKEN = 'EXPIRED_TOKEN',
    // Other error events, specified as frequent by the doc reference
    CONNECT_LOAN_TO_CONTINUE = 'CONNECT_LOAN_TO_CONTINUE',
    AUTH_EXPIRE = 'AUTH_EXPIRE',
    // Identity connection events
    IDENTITY_NOT_CONNECTED = 'IDENTITY_NOT_CONNECTED',
    // ... Add any other error events from docs here
}

/**
 * DIM Error event messages for DIM error events
 * Some are commented out because either are mostly unlikely to happen or are covered by a generic error message
 */
export const DIM_ERROR_EVENT_MESSAGES: Partial<Record<DIM_ERROR_EVENTS, string>> = {
  // [DIM_ERROR_EVENTS.DROPIN_CONTAINER_NOT_PASSED]: 'An element id must be passed to the Spinwheel instance.',
  // [DIM_ERROR_EVENTS.DROPIN_CONTAINER_UNAVAIL]: 'The dropin container element does not exist.',
  // [DIM_ERROR_EVENTS.DROPIN_TOKEN_UNAVAIL]: 'A token must be passed to the Spinwheel instance.',
  [DIM_ERROR_EVENTS.DROPIN_LOAD_FAIL]: 'We are having difficulty displaying your information. Please check that you are logged into the correct account and your credentials with your servicer have not changed. If you are still experiencing issues, please contact us at support@stridefunding or (214) 775-9960.',
  [DIM_ERROR_EVENTS.PAYMENT_FAIL]: 'An error occurred while processing the payment. If you are still experiencing issues, please contact us at support@stridefunding or (214) 775-9960.',
  // [DIM_ERROR_EVENTS.SUBSCRIPTION_FAIL]: 'An error occurred while saving the subscription.',
  [DIM_ERROR_EVENTS.API_TIMEOUT]: 'We cannot process your request right now, please try again later. If you are still experiencing issues, please contact us at support@stridefunding or (214) 775-9960.',
  // [DIM_ERROR_EVENTS.OPEN_METHOD_DUPLICATE_CALL]: 'There is already an open call to the handler.open method.',
  // [DIM_ERROR_EVENTS.EXIT_CALLBACK_UNAVAIL]: 'An exit callback function must be provided if the onExit callback is invoked by the dropin.',
  // [DIM_ERROR_EVENTS.SUCCESS_CALLBACK_UNAVAIL]: 'A success callback function must be provided if the onSuccess callback is invoked by the dropin.',
  [DIM_ERROR_EVENTS.DIM_UNAVAILABLE_OUTAGE]: 'Our student loan connector is unavailable right now, please check back later. If you are still experiencing issues, please contact us at support@stridefunding or (214) 775-9960.',
  // [DIM_ERROR_EVENTS.INVALID_MODULE_NAME]: 'An invalid module name was passed to the Spinwheel instance.',
  [DIM_ERROR_EVENTS.DIM_ERROR]: DIM_FALLBACK_ERROR_MESSAGE,
  [DIM_ERROR_EVENTS.INVALID_TOKEN]: 'The token provided is invalid. If you are still experiencing issues, please contact us at support@stridefunding or (214) 775-9960.',
  [DIM_ERROR_EVENTS.EXPIRED_TOKEN]: 'The token has expired. If you are still experiencing issues, please contact us at support@stridefunding or (214) 775-9960.',
  // Other error event messages, specified as frequent by the doc reference
  [DIM_ERROR_EVENTS.CONNECT_LOAN_TO_CONTINUE]: 'Please connect a loan to continue with the process.  If you are still experiencing issues, please contact us at support@stridefunding or (214) 775-9960.',
  [DIM_ERROR_EVENTS.AUTH_EXPIRE]: 'Your authentication has expired, please reload the page. If you are still experiencing issues, please contact us at support@stridefunding or (214) 775-9960.',

  // Identity connect error messages
  [DIM_ERROR_EVENTS.IDENTITY_NOT_CONNECTED]: 'We encountered an issue verifying your identity. Please continue with manual verification.',
  // ... Add any other user-friendly error messages here if new error events are documented
};
