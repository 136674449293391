import { PartnerType } from '../../../types/partner';
import { PartnerListData } from '../../../types/responseSchema/partnerData';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

/**
 * Method to retrieve partner list
 *
 * @returns partner list, used to power the FE
 */
const getPartnerList = async (partnerType: PartnerType, nameContains?: string): Promise<AxiosResponse<PartnerListData[]>> => {
  const searchParams: Record<string, string> = { partner_type: partnerType.toString() };

  // If nameContains is provided, add it to the search params
  if (nameContains) {
    searchParams['q'] = nameContains;
  }

  const queryParams = new URLSearchParams(searchParams);

  return await backendAuthenticatedAxios.get(`partner/get-partners?${queryParams.toString()}`);
};

export default getPartnerList;
