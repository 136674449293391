
import { CustomerFundingProduct } from '../../../state/user/types';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const getCustomerFundingProduct = async (applicationId: string): Promise<AxiosResponse<CustomerFundingProduct | null>> => {
  /* Backend request to get Customer Funding Product using an App Id (Partner use only). */

  const reqBody = {
    'originator_app_id': applicationId,
  };

  // Encode Partner name to account for spaces and reduce transformations across interfaces
  return await backendAuthenticatedAxios.post<CustomerFundingProduct>('/cfp/get-by-app-id', reqBody);
};

export default getCustomerFundingProduct;
