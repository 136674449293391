export type ProductType = 'ISA' | 'ISL' | 'DTA' | 'RIC' | 'ESL' | 'NCL' | 'PPL';

export const productTypes: Record<string, ProductType> = {
  'ISL': 'ISL',  // Note: 'Product type is ISL' for anonymous/no quote case
  'ISA': 'ISA',
  'DTA': 'DTA',
  'RIC': 'RIC',
  'ESL': 'ESL',
  'NCL': 'NCL',
  'PPL': 'PPL',
};
