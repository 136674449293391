import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

type StoreRepaymentExternalIdParams = {
    external_id: string;
};

/**
 * Stores the external ID of the user's repayment account
 */
const storeRepaymentExternalId = async (payload: StoreRepaymentExternalIdParams): Promise<AxiosResponse<void>> =>
  backendAuthenticatedAxios.post<void>('/repayment/store-repayment-external-id', payload);

export default storeRepaymentExternalId;
