import { backendAuthenticatedAxios } from '..';

type SpinwheelSessionTokenResponse = {
  response: JSON;
  token: string;
};

/**
 * Obtains a session token needed for Spinwheel DIM initialization
 */
const fetchSpinwheelSessionToken = async (): Promise<string | null> => {
  const response = await backendAuthenticatedAxios.post<SpinwheelSessionTokenResponse>(
    'spinwheel/create-session',
  );

  console.debug('Spinwheel session token response', response);

  return response.data.token;
};

export default fetchSpinwheelSessionToken;
