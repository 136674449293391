import { useRecoilState, useSetRecoilState } from 'recoil';

import { eligibilitySteps } from '../constants/eligibility';
import backend from '../lib/backend';
import b2bRegisterPartner from '../state/atoms/b2bRegisterPartner';
import fundingProductTerms from '../state/atoms/fundingProductTerms';
import hasUserSeenBankModal from '../state/atoms/hasUserSeenBankModal';
import isBankConnectError from '../state/atoms/isBankConnectError';
import persistIncomeVerificationModal from '../state/atoms/persistIncomeVerificationModal';
import { eligibilityActiveStep } from '../state/eligibilityProgress';
import universityEligibilityForm from '../state/forms/universityElegibilityForm/form';
import { quoteRequestApplicationResponse } from '../state/useQuoteRequestApplication';
import { useUser } from '../state/user';

import posthog from 'posthog-js';

export const useLogout = () => {
  const user = useUser();
  /**
   * @note For some reason useResetQuote (that uses useResetRecoilState) does not work in this context.
   * needs further investigation
   */
  const [ , setQuoteRequest ] = useRecoilState(quoteRequestApplicationResponse);
  const setIsBankConnectError = useSetRecoilState(isBankConnectError);
  const setEligibilityStep = useSetRecoilState(eligibilityActiveStep);
  const setFundingProductTerms = useSetRecoilState(fundingProductTerms);
  const setPartnerData = useSetRecoilState(b2bRegisterPartner);
  const [ , setFundingAmount ] = useRecoilState(universityEligibilityForm.fields.fundingAmount.valueState);
  const [ , setSeenBankModal ] = useRecoilState(hasUserSeenBankModal);
  const [ , setShouldPersistBankModal ] = useRecoilState(persistIncomeVerificationModal);

  const logout = () => {
    backend.signOut();
    setIsBankConnectError(false);
    setQuoteRequest(null);
    setFundingProductTerms(null);
    setEligibilityStep(eligibilitySteps.yourProgram.key);
    setPartnerData(null);
    setFundingAmount(null);
    setSeenBankModal(false);
    setShouldPersistBankModal(false);
    posthog.reset();    // Unlink future events from current user session
    user.refresh();
  };

  return logout;
};
