import { FunctionComponent } from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';

interface SimpleCheckListProps {
  horizontalGap: number;
  icon: StaticImageData;
  iconOffset: number;
  iconSize: number;
  items: string[];
  verticalGap: number;
}

const SimpleCheckList: FunctionComponent<SimpleCheckListProps> = ({
  horizontalGap,
  icon,
  iconOffset,
  iconSize,
  items,
  verticalGap,
}) => (
  <>
    {
      items.map((text, key) => (
        <Box key={key} mb={verticalGap}>
          <Box
            display='flex'
            justifyContent='flex-start'
          >
            <Box
              height={iconSize}
              pt={iconOffset}
              width={iconSize}
            >
              <Image
                alt=''
                layout='fixed'
                src={icon}
              />
            </Box>
            <Box ml={horizontalGap}>
              <Typography variant='subtitle1'>
                {text}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))
    }
  </>
);

export default SimpleCheckList;
