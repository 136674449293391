import { atom } from 'recoil';

import { sessionAtom } from '../../lib/recoil/persist';

// TODO remove after Clasp cut-over SSP-2819
export const claspBannerOpenState = atom<boolean>({
  key: 'claspBannerOpenState',
  default: false,
});

export const hasClaspBannerBeenOpened = sessionAtom<boolean>({
  key: 'hasClaspBannerBeenOpened',
  default: false,
});
