import { PortalChangeRequest } from '../../../../types/portalChangeRequest';
import { backendAuthenticatedAxios } from '../../index';

import { AxiosResponse } from 'axios';

const getChangeRequest = async (changeRequestId: string): Promise<AxiosResponse<PortalChangeRequest | null>> => {
  const reqBody = {
    'change_request_id': changeRequestId,
  };

  return backendAuthenticatedAxios.post<PortalChangeRequest>('/staff/portal-change-request/get-by-id', reqBody);
};

export default getChangeRequest;
