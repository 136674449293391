import {
  atom,
  RecoilState,
  RecoilValueReadOnly,
  selector,
} from 'recoil';

/**
 * Manages state associated with Autocomplete components that fetch options as the user types
 *
 * Designed to be compatible with [Material UI's Autocomplete component
 *  ](https://mui.com/api/autocomplete/)
 */
class ServerSideAutocomplete<T> {
  /**
   * Value of the input field
   */
  inputState: RecoilState<string>;

  /**
   * Options returned by passing `inputState` to `fetch`
   *
   * @note this is automatically memoized
   * @readonly
   */
  optionsState: RecoilValueReadOnly<T[]>;

  /**
   * Create a new ServerSideAutocomplete instance
   *
   * @param key used to prefix keys of Recoil pieces state
   * @param fetch callback that fetches the options
   * @param inputState (optional) value of the input field
   *
   * @example
   *  const dogBreedAutocomplete =
   *    new ServerSideAutocomplete<DogBreed>(
   *      'dogBreed',
   *      async (query) => {...},
   *      dogBreedInput,
   *    );
   */
  constructor(
    key: string,
    fetch: (query: string) => Promise<T[]>,
    inputState = atom<string>({
      key: `${key}/Autocomplete/input`,
      default: '',
    }),
  ) {
    this.inputState = inputState;

    // Create optionsState selector
    this.optionsState = selector<T[]>({
      key: `${key}/Autocomplete/options`,
      get: async ({ get }) => {
        return fetch(get(this.inputState));
      },
    });
  }
}

export default ServerSideAutocomplete;
