import { Disbursement } from '../../../../types/disbursement';
import { backendAuthenticatedAxios } from '../..';

import { AxiosResponse } from 'axios';

const getDisbursements = async (applicationIdList: string[]): Promise<AxiosResponse<Disbursement[] | null>> => {
  const reqBody = {
    'originator_app_ids': applicationIdList,
  };

  // Encode Partner name to account for spaces and reduce transformations across interfaces
  return await backendAuthenticatedAxios.post<Disbursement[]>('/staff/disbursement/get-by-ids', reqBody)
    .catch((err) => {
      const apiRes = err.response;

      // Absorb 404 error so we can deal with no disbursements in the FE gracefully
      if (apiRes.status === 404) {
        console.log('No Disbursements for Customer Funding Products Found - generate using UI');

        return new Promise(() => []);
      } else {
        throw new Error('No Disbursements for Customer Funding Products Found');
      }
    });
};

export default getDisbursements;
