import { FunctionComponent } from 'react';
import { useRecoilState } from 'recoil';
import Link from 'next/link';
import { Alert, AlertTitle, Link as MuiLink, Snackbar, Typography } from '@mui/material';

import { useTypedFeatureFlagPayload } from '../../hooks/useTypedFeatureFlagPayload';
import { claspBannerOpenState } from '../../state/atoms/claspBannerOpenState';
import { ClaspCutoverBannerPayload } from '../../types/featureFlagPayloads';

// TODO remove after Clasp cut-over SSP-2819
const ClaspBanner: FunctionComponent = () => {
  const [ isBannerOpen, setIsBannerOpen ] = useRecoilState(claspBannerOpenState);
  const claspCutoverBannerPayload = useTypedFeatureFlagPayload<ClaspCutoverBannerPayload>('clasp-cutover-banner');
  if (!claspCutoverBannerPayload) return null;

  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isBannerOpen}>
      <Alert
        onClose={() => setIsBannerOpen(false)}
        severity='info'
        sx={{ display: 'flex', alignItems: 'center' }}
        variant='filled'
      >
        <AlertTitle sx={{ fontWeight: 900 }}>
          {claspCutoverBannerPayload.title}
        </AlertTitle>
        <Typography>
          {`${claspCutoverBannerPayload.body} `}
          <Link href={claspCutoverBannerPayload.link} passHref>
            <MuiLink color='inherit'>
              here.
            </MuiLink>
          </Link>
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default ClaspBanner;
