import Form, { Field } from '../../../lib/recoil/Form';
import { ApplicationFormAnswers } from '../../../types/applicationAnswer';
import { Region } from '../../../types/location';

/**
 * Manages the state of the program questions form
 */
const programQuestionsForm = new Form({
  fields: {
    // Program step
    locations: new Field<Region[] | []>({
      key: 'locations',
      defaultValue: [],
      persists: false,
      rules: [
        {
          helperText: '',
          pattern: (value) =>
            value !== null,
          severity: 'off',
        },
      ],
    }),
    applicationFormAnswers:  new Field<ApplicationFormAnswers>({
      key: 'applicationFormAnswers',
      defaultValue: {},
      persists: false,
      rules: [
        {
          helperText: '',
          pattern: (value) =>
            value !== null,
          severity: 'off',
        },
      ],
    }),
    notes:  new Field<string>({
      key: 'notes',
      defaultValue: '',
      persists: false,
      rules: [
        {
          helperText: '',
          pattern: (value) =>
            value !== null,
          severity: 'off',
        },
      ],
    }),

  },
});

export default programQuestionsForm;
