import React, { FunctionComponent, lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import useCommonApplicationForm from '../../../hooks/formControllers/esl/useCommonApplicationForm';
import useDocumentsSubmitForm from '../../../hooks/formControllers/esl/useDocumentsSubmitForm';
import useProgramQuestionsSubmitForm from '../../../hooks/formControllers/esl/useProgramQuestionsSubmitForm';
import useAlert from '../../../hooks/useAlert';
import backend from '../../../lib/backend';
import updateStatusStudentSponsorshipApplication
  from '../../../lib/backend/requests/updateStatusStudentSponsorshipApplication';
import { commonApplicationProfileAtom } from '../../../state/atoms/commonApplicationProfile';
import { sponsorshipApplicationAtom } from '../../../state/atoms/sponsorshipApplication';
import { ValidationError } from '../../../types/errors/validation';
import { SponsorshipApplicationStatus } from '../../../types/sponsorshipApplication';

import { AxiosError } from 'axios';

const CommonApplicationProfileView = lazy(() => import('../profileSections/commonApplication'));
const ProgramQuestionsSubmit = lazy(() => import('../applicationSections/programQuestionsSubmit'));
const DocumentsSubmit = lazy(() => import('../applicationSections/documentsSubmit'));

interface EslReviewFormProps {
  handleClose: () => void;
}

const EslReviewForm: FunctionComponent<EslReviewFormProps> = ({ handleClose }) => {
  const [ sponsorshipApplication, setSponsorshipApplication ] = useRecoilState(sponsorshipApplicationAtom);
  const [ commonApplicationProfile, setCommonApplicationProfile ] = useRecoilState(commonApplicationProfileAtom);
  const [ isLoading, setIsLoading ] = useState<boolean>(!sponsorshipApplication || !commonApplicationProfile);
  const { showErrorAlert, showSuccessAlert } = useAlert();

  const showLoadingSpinner = useMemo(() => isLoading || !sponsorshipApplication || !commonApplicationProfile, [ isLoading, sponsorshipApplication, commonApplicationProfile ]);

  const [ fieldErrorHighlightEnabled, setFieldErrorHighlightEnabled ] = useState(false);

  const { submitForm: submitCommonApplicationForm } = useCommonApplicationForm();
  const { submitForm: submitProgramQuestionsForm } = useProgramQuestionsSubmitForm(sponsorshipApplication);
  const { submitForm: submitDocumentsForm } = useDocumentsSubmitForm(sponsorshipApplication, null);

  const loadingIndicator = useMemo(() => (
    <Box alignItems='center' display='flex' justifyContent='center' minHeight='100vh'>
      <CircularProgress color='primary' />
    </Box>
  ), []);

  const handleError = useCallback((error: unknown, message: string) => {
    console.error(error);
    showErrorAlert(message);
  }, [showErrorAlert]);

  const getCommonApplicationProfile = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await backend.getCommonApplicationProfile();
      setCommonApplicationProfile(data);
    } catch (error: unknown) {
      setCommonApplicationProfile(null);
      handleError(error, 'There was an error loading your profile info, please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [ handleError, setCommonApplicationProfile ]);

  const getStudentSponsorshipApplication = useCallback(async () => {
    setIsLoading(true);

    try {
      const { data } = await backend.getStudentSponsorshipApplications();

      if (data.length) {
        const [firstSponsorshipApplication] = data;
        setSponsorshipApplication(firstSponsorshipApplication);
      }
    } catch (error) {
      setSponsorshipApplication(null);
      handleError(error, 'There was an error loading your application info, please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [ handleError, setSponsorshipApplication ]);

  const reloadEslStudentInfo = useCallback(async () => {
    try {
      await Promise.all([ getCommonApplicationProfile(), getStudentSponsorshipApplication() ]);
    } catch (error) {
      // Rephrase the error message to be more user-friendly
      throw new Error('There was an error refreshing your application info. Please refresh the page.');
    }
  }, [ getCommonApplicationProfile, getStudentSponsorshipApplication ]);

  // Ensure that the ESL student's common application profile is loaded

  useEffect(() => {
    if (!commonApplicationProfile) getCommonApplicationProfile();
  }, [ commonApplicationProfile, getCommonApplicationProfile, setCommonApplicationProfile ]);

  // Ensure that the ESL student's sponsorship application is loaded

  useEffect(() => {
    if (!sponsorshipApplication) getStudentSponsorshipApplication();
  }, [ sponsorshipApplication, setSponsorshipApplication, getStudentSponsorshipApplication ]);

  /**
   * Handle the submission of all ESL student application forms in parallel.
   */
  const handleFormsSubmission = useCallback(async () => {
    // Array of ESL student application forms to submit
    const formSubmissions = [
      { name: 'Common Application Form', submit: submitCommonApplicationForm },
      { name: 'Program Questions Form', submit: submitProgramQuestionsForm },
      { name: 'Documents Form', submit: submitDocumentsForm },
    ];

    // Submit all ESL student application forms in parallel
    const results = await Promise.allSettled(formSubmissions.map((form) => form.submit()));

    // Check if all ESL student application forms were successfully submitted or not and handle errors
    results.forEach((result, index) => {
      // TODO handle partially approved forms e.g. if one form is rejected, the others are still saved but the changes are not reflected in the FE SSP-2609
      // TODO handle no-changes submissions SSP-2610
      if (result.status === 'rejected') {
        let errorMessage = 'There was an error saving your application info, please try again later.';

        if (result.reason instanceof ValidationError) {
          errorMessage = result.reason.message;
        } else if (result.reason?.response?.data) {
          errorMessage = result.reason.response.data.detail || errorMessage;
        }

        throw new Error(`${formSubmissions[index].name} error: ${errorMessage}`);
      }
    });

    // Show success alert if all ESL student application forms were successfully submitted and reload the ESL student's common application profile and sponsorship application
    showSuccessAlert('Application info saved successfully.');
  }, [ submitCommonApplicationForm, submitProgramQuestionsForm, submitDocumentsForm, showSuccessAlert ]);

  /**
   * Handle the transition of the ESL student's sponsorship application status to NEW_APPLICANT.
   */
  const handleStatusTransition = useCallback(async () => {
    try {
      await updateStatusStudentSponsorshipApplication(sponsorshipApplication?.id as string, SponsorshipApplicationStatus.NEW_APPLICANT);
      showSuccessAlert('Application successfully submitted.');
    } catch (error) {
      const axiosError = error as AxiosError<{ detail: string }>;
      console.error(error);

      throw new Error(axiosError.response?.status === 400 ? axiosError.response?.data?.detail : 'There was an error submitting your application, please try again later.');
    }
  }, [ showSuccessAlert, sponsorshipApplication ]);

  const handleConfirm = useCallback(async () => {
    setFieldErrorHighlightEnabled(true);
    setIsLoading(true);

    try {
      await handleFormsSubmission();
      await handleStatusTransition();
      await reloadEslStudentInfo();
      handleClose();
    } catch (err: any) {
      // Cannot use isCustomError - non standard error format (no data or detail)
      showErrorAlert(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [ handleClose, handleFormsSubmission, handleStatusTransition, reloadEslStudentInfo, showErrorAlert ]);

  return (
    <Suspense fallback={loadingIndicator}>
      {showLoadingSpinner && loadingIndicator}
      <Box display={showLoadingSpinner ? 'none' : 'auto'} id='application-review' px={3} py={2}>
        <DialogTitle>
          <Typography color='primary' variant='h1'>
            Application review
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ px: 3, py: 2 }}>
          <Stack>
            <Typography color='primary' variant='h1'>
              Profile review
            </Typography>
            <CommonApplicationProfileView
              commonApplicationProfile={commonApplicationProfile}
              setCommonApplicationProfile={setCommonApplicationProfile}
              showErrors={fieldErrorHighlightEnabled}
              showSubmitButton={false}
              sponsorshipApplicationSubstatus={sponsorshipApplication?.substatus ?? null}
            />
          </Stack>
          <Stack mt='2rem' spacing='2rem'>
            <ProgramQuestionsSubmit
              setSponsorshipApplication={setSponsorshipApplication}
              showErrors={fieldErrorHighlightEnabled}
              showSubmitButton={false}
              sponsorshipApplication={sponsorshipApplication}
            />
          </Stack>
          <Stack mt='2rem' spacing='2rem'>
            <DocumentsSubmit
              setSponsorshipApplication={setSponsorshipApplication}
              showErrors={fieldErrorHighlightEnabled}
              showSubmitButton={false}
              sponsorshipApplication={sponsorshipApplication}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box display='flex' justifyContent='space-evenly' width='100%'>
            <Button autoFocus id='application-review-confirm-action' onClick={handleConfirm} size='small' sx={{ m: 1 }} variant='contained'>
              Confirm
            </Button>
            <Button id='application-review-cancel-action' onClick={handleClose} size='small' sx={{ m: 1 }} variant='outlined'>
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Suspense>
  );
};

export default EslReviewForm;
