import LogRocket from 'logrocket';
import TagManager from 'react-gtm-module';

type AdditionalMetadata = {
  [key: string]: any;
};

type EventMetadata = {
  route?: string;
  object?: string;
  htmlType?: string;
  api?: string;
  underwriteDecision?: string;
  fundingPartner?: string;
  href?: string;
  text?: string;
  application?: string;
};

type DestinationOptions = {
  gtm: boolean;
  logrocket: boolean;
};

/**
 * Send an event with metadata to both Google Tag Manager and LogRocket.
 *
 * @param {string} eventName - Unique name of the event in camelCase.
 * @param {EventMetadata & AdditionalMetadata} [eventMetadata] - Additional data payload captured with the event. The metadata can include predefined fields such as route, object, and application, as well as any number of additional fields as defined in AdditionalMetadata.
 * @param {DestinationOptions} [destinationOptions={ gtm: true, logrocket: true }] - Options to specify the destinations where the event should be sent. By default, events are sent to both Google Tag Manager (gtm) and LogRocket (logrocket).
 */
const triggerEvent = (eventName: string, eventMetadata?: EventMetadata & AdditionalMetadata, destinationOptions: DestinationOptions = { gtm: true, logrocket: true }): void => {
  if (destinationOptions.gtm) TagManager.dataLayer({ 'dataLayer': { 'event': `portal.${eventName}`, ...eventMetadata }});
  if (destinationOptions.logrocket) LogRocket.track(eventName, { ...eventMetadata });
};

export default triggerEvent;
