import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

type passwordTemplate = 'reset' | 'create';

const sendPasswordResetEmail = async (email: string, template: passwordTemplate = 'reset'): Promise<AxiosResponse<void>> => {
  console.log('Sending reset password email');

  const reqBody = {
    'template': template,
    'email': email,
  };

  return backendAuthenticatedAxios.post<void>(
    '/account/reset-password', reqBody,
  );
};

export default sendPasswordResetEmail;
