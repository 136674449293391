import palette from './palette';

const tableOverrides = {
  MuiTableCell: {
    styleOverrides: {
      head: {
        backgroundColor: palette.grey.A800,
        color: palette.text.secondary,
        lineHeight: 'inherit',
        paddingTop: 12,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 10,
        borderBottom: 'none',
      },
      body: {
        borderColor: palette.grey.A700,
        fontWeight: 800,
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        '&$active': {
          color: palette.text.secondary,
        },
      },
    },
  },
};

export default tableOverrides;
