import { userRoles } from '../../constants/userRoles';

import { RoutePermissions } from './types';

const permissionsOptions: Record<string, RoutePermissions> = {
  all: [ userRoles.anonymous, userRoles.customer, userRoles.partner, userRoles.staff ],
  anonymous: [userRoles.anonymous],
  authenticated: [ userRoles.partner, userRoles.customer, userRoles.staff ],
  partner: [userRoles.partner],
  staff: [userRoles.staff],
  partnerOrStaff: [ userRoles.partner, userRoles.staff ],
  customer: [userRoles.customer],
  customerOrAnonymous: [ userRoles.anonymous, userRoles.customer ],
  none: [],
};

export default permissionsOptions;
