import { atom } from 'recoil';

import { AlertStatus } from '../../types/alerts/alertStatus';

const alertStatusAtom = atom<AlertStatus | null>({
  key: 'alertStatusAtom',
  default: null,
});

export default alertStatusAtom;
