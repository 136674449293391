import { backendAuthenticatedAxios } from '../..';

const generateNACHA = async (disbursementIds: string[]) => {
  const reqBody = {
    'disbursement_ids': disbursementIds,
  };

  return backendAuthenticatedAxios.post('/staff/disbursement/generate-nacha', reqBody);
};

export default generateNACHA;
