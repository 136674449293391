import palette from './palette';

const inputOverrides = {
  MuiFormControl: {
    styleOverrides: {
      root: {
        marginBottom: '10px',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, 6px) scale(0.75)',
        },
        shrink: {
          transform: 'translate(14px, 6px) scale(0.75)',
        },
        '&.MuiFormLabel-root': {
          fontSize: 14,
        },
        '&.MuiInputLabel-shrink.Mui-focused': {
          transform: 'translate(14px, 6px) scale(0.75)',
          color: palette.text.secondary,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        padding: 0,
        lineHeight: '20.8px',
        paddingTop: '7px',
        '& fieldset legend': {
          display: 'none',
        },
        '& fieldset': {
          height: '50px',
          top: 0,
          borderColor: 'rgba(0, 0, 0, 0.23)',
          borderRadius: 10,
        },
        '& .MuiInputAdornment-root': {
          marginRight: '24px',
        },
      },
      input: {
        lineHeight: '20.8px',
        fontSize: 16,
        fontWeight: 500,
        height: '21px',
        padding: '14px 16px 8px',
        '&:focus': {
          paddingBottom: '8px',
        },
      },
      notchedOutline: {
        borderColor: palette.primary.main,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginTop: '2px',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        marginBottom: 10,
        '&[class*="MuiOutlinedInput-root"]': {
          paddingTop: 7,
          paddingBottom: 7,
        },
      },
      paper: {
        margin: '-8px 0 0',
        boxShadow: '0px 2px 10px rgba(5, 30, 39, 0.2)',
        borderRadius: '0 0 5px 5px',
      },
      option: {
        '&[data-focus="true"]': {
          backgroundColor: palette.primary.light,
        },
      },
      endAdornment: {
        top: 'calc(50% - 10px)',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        paddingLeft: 16,
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        '&:focus': {
          backgroundColor: 'white',
        },
      },
    },
  },
};

export default inputOverrides;
