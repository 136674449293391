import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const updateTermsAcceptedDateTime = async (
  customerRepaymentProductId: string,
): Promise<AxiosResponse<void>> => {
  return backendAuthenticatedAxios.post(
    `/repayment/crp/${customerRepaymentProductId}/update-terms-accepted-date-time`,
  );
};

export default updateTermsAcceptedDateTime;
