import React, { FunctionComponent } from 'react';
import { useRecoilState } from 'recoil';
import { Dialog } from '@mui/material';

import { eslApplicationReviewModalState } from '../../state/atoms/eslApplicationReviewModalState';

import EslReviewForm from './review/EslReviewForm';

const EslApplicationReviewModal: FunctionComponent = () => {
  const [ isOpen, setIsOpen ] = useRecoilState(eslApplicationReviewModalState);

  const handleCloseModal = () => setIsOpen(false);

  return (
    <Dialog
      fullWidth={true}
      maxWidth='xl'
      onClose={handleCloseModal}
      open={isOpen}
    >
      <EslReviewForm handleClose={handleCloseModal} />
    </Dialog>
  );
};

export default EslApplicationReviewModal;
