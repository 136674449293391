import { atom } from 'recoil';

import { FundingProductTerms } from '../../types/responseSchema/fundingProductTerms';

const fundingProductTerms = atom<FundingProductTerms | null >({
  key: 'fundingProductTerms',
  default: null,
});

export default fundingProductTerms;
