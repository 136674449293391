import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const createQuote = async (
  email: string,
  fptUid: string,
  fptVersion: number,
  fundingAmount: number,
): Promise<AxiosResponse<void>> => {
  console.log(`createQuote: Creating new quote for customer with uid: ${fptUid} and version ${fptVersion}`);

  const requestData = {
    'email': email,
    'fpt_uid': fptUid,
    'fpt_version': fptVersion,
    'funding_amount': fundingAmount,
  };

  return backendAuthenticatedAxios.post<void>(
    '/quote/submit-fixed', requestData,
  );
};

export default createQuote;
