import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

/**
 * Fetch selected accounts information from user's repayment account
 */
const fetchAndStoreRepaymentAccounts = async (): Promise<AxiosResponse<void>> =>
  backendAuthenticatedAxios.post<void>('/repayment/user/fetch-and-store-repayment-accounts');

export default fetchAndStoreRepaymentAccounts;
