import { BorrowerAccountProfile } from '../../../types/borrowerAccountProfile';
import { backendAuthenticatedAxios } from '../index';

import { AxiosResponse } from 'axios';

type SubmitBorrowerAccountProfileConnectionProps = {
    borrowerAccountProfileId: string;
    status: string;
};

const submitBorrowerAccountProfileConnection = async ({ borrowerAccountProfileId, status } : SubmitBorrowerAccountProfileConnectionProps ): Promise<AxiosResponse<BorrowerAccountProfile[]>> => {
  const requestData = {
    status,
  };

  return await backendAuthenticatedAxios.post(`/repayment/user/${borrowerAccountProfileId}/borrower-account-profile-connection`, requestData);
};

export default submitBorrowerAccountProfileConnection;
