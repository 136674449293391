import {
  SponsorshipApplicationStatus,
  SponsorshipApplicationSubstatus, StudentSponsorshipApplication,
} from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '../index';

import { AxiosResponse } from 'axios';

const updateStatusStudentSponsorshipApplication = async (
  sponsorshipApplicationId: string,
  status: SponsorshipApplicationStatus | SponsorshipApplicationSubstatus,
): Promise<AxiosResponse<StudentSponsorshipApplication | null>> => {
  return backendAuthenticatedAxios.patch(`/esl-student/${sponsorshipApplicationId}/update-status`, { 'status': status });
};

export default updateStatusStudentSponsorshipApplication;
