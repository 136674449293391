import { EmployeeInfo } from '../../../types/responseSchema/repaymentPartner';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

export interface GetRepaymentEmployeesResponse {
  data: EmployeeInfo[];
}

const getRepaymentEmployees = async (partnerId: string): Promise<AxiosResponse<GetRepaymentEmployeesResponse>> => {
  return await backendAuthenticatedAxios
    .get(`/repayment/employer/${partnerId}/employee/get`);
};

export default getRepaymentEmployees;
