import { atom } from 'recoil';

import { ConfirmationStatus } from '../../types/confirmations/confirmationStatus';

const confirmationStatusAtom = atom<ConfirmationStatus>({
  key: 'confirmationStatus',
  default: { dialogOpen: false, onClick: () => {} },
});

export default confirmationStatusAtom;
