import { FunctionComponent } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';

import theme from '../../styles/theme';
import { BorrowerAccountProfile, BorrowerAccountProfileConnectionStatus } from '../../types/borrowerAccountProfile';
import { LoanServicerConnectedStatusChip } from '../chips/LoanServicerConnectionStatusChip';

interface LoanServicerAccountCardProps {
  borrowerAccount: BorrowerAccountProfile;
  disabled?: boolean;
  onUpdate: ({ id, connectionStatus }: { id: string; connectionStatus: BorrowerAccountProfileConnectionStatus }) => void;
}

const LoanServicerAccountCard: FunctionComponent<LoanServicerAccountCardProps> = ({ borrowerAccount, onUpdate, disabled = false }) => (
  <Grid container direction={'column'} item p={2} sx={{ border: 'solid 2px', borderColor: theme.palette.primary.main, borderRadius: '14px', backgroundColor: 'white' }}>
    <Grid container direction='row' item justifyContent={'space-between'} mb={4}>
      <Typography sx={{ lineHeight: '32px' }} variant='h3'>{borrowerAccount.servicer_display_name}</Typography>
      {
        borrowerAccount.connection_status === BorrowerAccountProfileConnectionStatus.CONNECTED
          ? (
            <LoanServicerConnectedStatusChip
              key={`${borrowerAccount.servicer_display_name}-connected`}
              label={'Connected'}
              variant='outlined'
            />
          )
          : null
      }
    </Grid>
    <Grid alignItems={'center'} container direction='row' item mb={2}>
      <Typography variant='h4'>Estimated Outstanding Balance:</Typography>&nbsp;
      <Typography sx={{ lineHeight: '23.4px' }}>{borrowerAccount.account_details.outstanding_balance}</Typography>
    </Grid>
    <Grid container direction='row' item>
      <Grid alignItems={'center'} container direction='row' item xs={8}>
        <Typography variant='h4'>Estimated Interest Rate:</Typography>&nbsp;
        <Typography sx={{ lineHeight: '23.4px' }}>{borrowerAccount.account_details.interest_rate}</Typography>
      </Grid>
      <Grid alignItems={'center'} container direction='row' item xs={4}>
        <Typography variant='h4'>Status:</Typography>&nbsp;
        <Typography sx={{ lineHeight: '23.4px' }}>{borrowerAccount.connection_status}</Typography>
      </Grid>
    </Grid>
    <Grid container item justifyContent={'center'}>
      <LoadingButton
        disabled={disabled}
        loading={borrowerAccount.connection_status === BorrowerAccountProfileConnectionStatus.IN_PROGRESS}
        loadingIndicator={
          borrowerAccount.connection_status === BorrowerAccountProfileConnectionStatus.DISCONNECTED
            ? 'Connecting...'
            : 'Disconnecting...'
        }
        onClick={
          () => onUpdate({
            id: borrowerAccount.id,
            connectionStatus: borrowerAccount.connection_status === BorrowerAccountProfileConnectionStatus.DISCONNECTED
              ? BorrowerAccountProfileConnectionStatus.CONNECTED
              : BorrowerAccountProfileConnectionStatus.DISCONNECTED,
          },
          )
        }
        sx={{ marginTop: 6 }}
        variant='contained'
      >
        {
          borrowerAccount.connection_status === BorrowerAccountProfileConnectionStatus.DISCONNECTED
            ? 'Connect Account'
            : 'Disconnect Account'
        }
      </LoadingButton>
    </Grid>
  </Grid>
);

export default LoanServicerAccountCard;
