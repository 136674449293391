import { PortalChangeRequest, PortalChangeRequestStatus } from '../../../../types/portalChangeRequest';
import { backendAuthenticatedAxios } from '../..';

import { AxiosResponse } from 'axios';

const updateChangeRequestStatus = async (
  portalChangeRequestId: string, target: PortalChangeRequestStatus,
): Promise<AxiosResponse<PortalChangeRequest | null>> => {
  const reqBody = { status: target };

  return backendAuthenticatedAxios.patch<PortalChangeRequest>(
    `/staff/portal-change-request/${portalChangeRequestId}/update-status`,
    reqBody,
  );
};

export default updateChangeRequestStatus;
