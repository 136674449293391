import { FunctionComponent, ReactNode } from 'react';
import { Box, Button, ButtonProps, Typography } from '@mui/material';

import { Property } from 'csstype';
import TextAlign = Property.TextAlign;

interface InfoModalProps {
  cancelCaption?: string;
  cancelProps?: ButtonProps;
  continueCaption?: string;
  continueHref?: string;
  continueProps?: ButtonProps;
  continueStartIcon?: ReactNode | null;
  height?: number | 'auto';
  successCaption?: string;
  successProps?: ButtonProps;
  onCancel?: () => void;
  onContinue?: () => void;
  onSuccess?: () => void;
  showCancelButton?: boolean;
  showContinueButton?: boolean;
  showSuccessButton?: boolean;
  subtitle?: string;
  title?: string;
  titleAlign?: TextAlign;
  horizontalButtons?: boolean;
  width?: number;
}

const InfoModal: FunctionComponent<InfoModalProps> = ({
  cancelCaption = 'Cancel',
  cancelProps,
  children,
  continueCaption = 'Continue',
  continueHref,
  continueProps,
  continueStartIcon = null,
  height = 'auto',
  successCaption = 'Finish',
  successProps,
  onCancel,
  onContinue,
  onSuccess,
  showCancelButton = true,
  showContinueButton = true,
  showSuccessButton = false,
  subtitle,
  title,
  titleAlign = 'left',
  horizontalButtons = false,
  width,
}) => {
  return (
    <Box
      className='info-modal'
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      minHeight={height}
      p={3.75}
      width={width ? width : undefined}
    >
      <Box>
        {
          title
            ? (
              <Box mb={2}>
                <Typography textAlign={titleAlign} variant='h2'>
                  {title}
                </Typography>
              </Box>
            )
            : null
        }
        {
          subtitle
            ? (
              <Box mb={2}>
                <Typography variant='subtitle1'>
                  {subtitle}
                </Typography>
              </Box>
            )
            : null
        }
      </Box>
      <Box
        flexGrow={1}
      >
        {children}
      </Box>
      <Box sx={
        horizontalButtons
          ? {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }
          : {}
      }
      >
        {
          ( onContinue && showContinueButton )
          || typeof continueHref === 'string'
            ? (
              <Box mb={horizontalButtons ? 0 : 1.75}>
                <Button
                  color='primary'
                  fullWidth
                  href={continueHref}
                  onClick={onContinue}
                  startIcon={continueStartIcon}
                  variant='contained'
                  {...continueProps}
                >
                  {continueCaption}
                </Button>
              </Box>
            )
            : null
        }
        {
          cancelCaption && showCancelButton &&  (
            <Box mb={horizontalButtons ? 0 : 2}>
              <Button
                fullWidth
                onClick={onCancel}
                variant={horizontalButtons ? 'outlined' : 'text'}
                {...cancelProps}
                data-cy='cancel-button'
                id='cancel-button'
              >
                {cancelCaption}
              </Button>
            </Box>
          )
        }
        {
          successCaption && showSuccessButton &&  (
            <Box mb={horizontalButtons ? 0 : 2}>
              <Button
                fullWidth
                onClick={onSuccess}
                {...successProps}
              >
                {successCaption}
              </Button>
            </Box>
          )
        }
      </Box>
    </Box>
  );
};

export default InfoModal;
