import { sessionAtom } from '../../lib/recoil/persist';

// Data type to hold post bank connect context
export type PostBankConnectionHandler = {
    cancelHandler: () => void;     // On cancel/skip (usually same as above, but allows customization if needed)
    redirect: boolean;      // Will be used to show redirect screen (future story)
    successHandler: () => void;     // On successful bank account connection
};

const defaultPostBankConnectionHandler = {
  cancelHandler: () => {},
  redirect: false,
  successHandler: () => {},
};

const postBankConnectionHandler = sessionAtom<PostBankConnectionHandler>({
  key: 'postBankConnectionHandler',
  default: defaultPostBankConnectionHandler,
});

export default postBankConnectionHandler;
