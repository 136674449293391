import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

type LiabilityAccountPayment = {
  id: string;
  amount: number;
};

type SubmitMultiplePaymentRequestProps = {
  customerRepaymentProductId: string;
  liabilityAccountPayments: LiabilityAccountPayment[];
};

const submitMultiplePaymentRequest = async ({
  customerRepaymentProductId,
  liabilityAccountPayments,
}: SubmitMultiplePaymentRequestProps): Promise<AxiosResponse<void>> => {
  const requestData = {
    'customer_repayment_product_id': customerRepaymentProductId,
    'liability_account_payments': liabilityAccountPayments,
  };

  return await backendAuthenticatedAxios
    .post('/repayment/employer/create-multiple-payment-request', requestData);
};

export default submitMultiplePaymentRequest;
