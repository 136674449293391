import { backendAuthenticatedAxios } from '..';
import { BackendUserResponse } from '../types';

import { AxiosResponse } from 'axios';

const fetchUser = async (): Promise<AxiosResponse<BackendUserResponse>> => {
  console.log('Fetching user');

  return backendAuthenticatedAxios.get<BackendUserResponse>('/account/me');
};

export default fetchUser;
