import { backendAuthenticatedAxios } from '../..';

const approveFinwisePayoffs = async (disbursementIds: string[]) => {
  const reqBody = {
    'disbursement_ids': disbursementIds,
  };

  return  backendAuthenticatedAxios.post<void>('/staff/disbursement/approve-finwise-payoff', reqBody);
};

export default approveFinwisePayoffs;
