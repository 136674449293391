import { SponsorshipOpportunity } from '../../../../types/sponsorshipOpportunity';
import { backendAuthenticatedAxios } from '../..';

import { AxiosResponse } from 'axios';

const getSponsorshipOpportunities = async (): Promise<AxiosResponse<SponsorshipOpportunity[]>> => {
  return await backendAuthenticatedAxios.get('/staff/sponsorship-opportunities/');
};

export default getSponsorshipOpportunities;
