import { FunctionComponent } from 'react';

import routes from '../../../lib/routes';
import { useUser } from '../../../state/user';
import HeaderLink from '../../typography/HeaderLink';

import AccountOptionsDropdown from './AccountOptionsDropdown';

const AccountOptions: FunctionComponent = () => {
  const user = useUser();

  return (
    <>
      {
        user.isSignedIn
          ? <AccountOptionsDropdown />
          : <HeaderLink displayName={'Login'} href={routes.internal.accountLogin.url} />
      }
    </>
  );
};

export default AccountOptions;
export { AccountOptionsDropdown };
