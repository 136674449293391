import { CustomerFundingProduct } from '../../../../state/user/types';
import { backendAuthenticatedAxios } from '../..';
import { BulkActionResponse } from '../../types';

import { AxiosResponse } from 'axios';

type GenerateFinwiseLoans = BulkActionResponse<CustomerFundingProduct>;

const generateFinwiseLoans = async (applicationIds: string[]): Promise<AxiosResponse<GenerateFinwiseLoans>> => {
  const reqBody = { 'originator_app_ids': applicationIds };

  return backendAuthenticatedAxios.post<GenerateFinwiseLoans>('/staff/cfp/generate-finwise-loan', reqBody)
    .catch(() => {
      throw new Error('An unexpected error ocurred during loans generation');
    });
};

export default generateFinwiseLoans;
