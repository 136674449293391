import React, { FunctionComponent, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

type UpdateAccountNumberDialogProps = {
  onConfirm: (accountNumber: string) => Promise<void>;
  onClose: () => void;
};

export const UpdateAccountNumberDialog: FunctionComponent<UpdateAccountNumberDialogProps> = ({ onConfirm, onClose }) => {
  const [ accountNumber, setAccountNumber ] = useState<string | null>();
  const [ accountNumberConfirmation, setAccountNumberConfirmation ] = useState<string | null>();
  const [ errorMessage, setErrorMessage ] = useState<string | null>();

  const handleAccountConfirmation = async () => {
    if (!accountNumber) {
      setErrorMessage('Please fill your account number before confirming');

      return;
    }

    if (accountNumber !== accountNumberConfirmation) {
      setErrorMessage('Please make sure your account numbers match');

      return;
    }

    // clean form
    setAccountNumber(null);
    setAccountNumberConfirmation(null);
    setErrorMessage(null);

    // continue confirm chain
    onConfirm(accountNumber);
  };

  return (
    <Dialog
      onClose={onClose}
      open={true}
    >
      <Box id='update-account-number-dialog' sx={{ px: 3, py: 2, maxWidth: '500px' }}>
        <DialogTitle>Please enter your account number</DialogTitle>
        <DialogContent>
          You can find your account number by checking a monthly statement from your servicer.<br />
          Please ensure the account number you enter matches the one on your statement.
          <Box mt={3}>
            <TextField
              fullWidth id='account-number-input' label='Account Number' onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => {
                  setAccountNumber(event.target.value);
                }
              } placeholder='Account number'
              value={accountNumber}
            />
            <TextField
              error={!!errorMessage} fullWidth helperText={errorMessage} id='account-number-confirmation-input' label='Retype Account Number' onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => {
                  setAccountNumberConfirmation(event.target.value);
                }
              } placeholder='Account number'
              value={accountNumberConfirmation}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display='flex' justifyContent='space-evenly' width='100%'>
            <Button autoFocus id='update-account-number-dialog-confirm-action' onClick={handleAccountConfirmation} size='small' sx={{ m: 1 }} variant='contained'>
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
