import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

type SubmitUnsupportedServicerDetails = {
    servicerName: string;
    isFederalLoan: string;
};

const submitUnsupportedServicerDetails = async ({ servicerName, isFederalLoan }: SubmitUnsupportedServicerDetails): Promise<AxiosResponse<void>> => {
  const requestData = {
    'servicer_name': servicerName,
    'is_federal_loan': isFederalLoan,
  };

  return await backendAuthenticatedAxios
    .post('/repayment/user/submit-servicer', requestData);
};

export default submitUnsupportedServicerDetails;
