import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

import env from './lib/env';

console.log('Sentry ENV:', env.var.SENTRY_ENV);

Sentry.init({
  dsn: env.var.SENTRY_DSN,
  environment: env.var.SENTRY_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,

  integrations: [
    new BrowserTracing({
      tracingOrigins: [ '^https?:\\/\\/localhost.*', '^https?:\\/\\/(.*?)stridefunding.com/.*', /^\// ],
      // ... other options
    }),
  ],

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
