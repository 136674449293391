import { CallbackInterface, RecoilState, useRecoilCallback } from 'recoil';

import { FieldDict, InferFromFieldDict } from './types';

import Form from '.';

/**
 * Returns a memoized function that submits
 *  data in the given `Form` instance
 *  by triggering the given `request`.
 *  Optionally stores the response in `responseState`
 *
 * @param form
 *  `Form` instance to be submitted
 * @param request
 *  Callback function that makes an API call
 * @param responseState
 *  (optional) Recoil state that holds the return value of `request()`
 *
 * @example
 *  const Component: FunctionComponent = () => {
 *    const submit = Form.useSubmit(
 *      myForm,
 *      (data) => myAsyncFunction(data)
 *    );
 *
 *    handleSubmit = () => submit();
 *    ...
 *  }
 */
const useSubmit = <
  Response extends unknown,
  Fields extends FieldDict,
>(
    form: Form<Fields>,
    request: (data: InferFromFieldDict<Fields>) => Promise<Response>,
    responseState?: RecoilState<Response>,
  ): () => Promise<Response> =>
    useRecoilCallback(({ set, snapshot }: CallbackInterface) => async () => {
    // Send request with data
      const response = await request(
        await form.getFieldValuesSnapshot(snapshot),
      );

      // Store the response to state
      if (responseState)
        set<Response>(
          responseState,
          response,
        );

      return response;
    }, [ form, request, responseState ]);

export default useSubmit;
