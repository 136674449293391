import React, { FunctionComponent } from 'react';
import { useRecoilState } from 'recoil';
import { Dialog } from '@mui/material';

import { sponsorshipAgreementActiveModal } from '../../../state/atoms/sponsorshipAgreementLoadingModalOpenState';
import { SPONSORSHIP_AGREEMENT_MODAL_STEPS } from '../../../types/sponsorshipAgreementModals';

import HangTightModal from './HangTightModal';
import MailingAddressModal from './MailingAddressModal';

/**
 * SponsorshipAgreementModalContainer is a React component that acts as a container for various Sponsorship Agreement modals.
 * It uses Recoil to manage the state of the active modal and conditionally renders the appropriate Sponsorship
 * modal component based on the current active modal's state. This container ensures that the correct modal
 * is displayed to the user based on their interaction with the Sponsorship Agreement functionalities.
 *
 * All necessary Sponsorship Agreement modal components should be included in this container.
 * It encapsulates the logic for rendering specific modals such as the MailingAddressModal and HangTightModal,
 * based on the active modal state.
 *
 * @component
 */
const SponsorshipAgreementModalContainer: FunctionComponent = () => {
  const [ activeModal, setActiveModal ] = useRecoilState(sponsorshipAgreementActiveModal);

  // Conditional rendering of Sponsorship modals: neither the provider nor the modals are rendered if no modal is currently active
  if (!activeModal) return null;

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      onClose={() => setActiveModal(null)}
      open={true}
    >
      {activeModal === SPONSORSHIP_AGREEMENT_MODAL_STEPS.MAILING_ADDRESS_CONFIRMATION && <MailingAddressModal />}
      {activeModal === SPONSORSHIP_AGREEMENT_MODAL_STEPS.HANG_TIGHT && <HangTightModal />}
    </Dialog>
  );
};

export default SponsorshipAgreementModalContainer;
