import createBreakpoints from '@mui/system/createTheme/createBreakpoints';

import palette from './palette';

const breakpoints = createBreakpoints({});

const appBarOverrides = {
  MuiAppBar: {
    styleOverrides: {
      positionSticky: {
        boxShadow: 'none',
        borderWidth: 0,
        borderBottomWidth: '1px',
        borderBottomColor: palette.grey.A800,
        borderStyle: 'solid',
        marginBottom: '40px',
        [breakpoints.down('md')]: {
          marginBottom: '24px',
        },
      },
      colorDefault: {
        backgroundColor: 'white',
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      regular: {
        display: 'flex',
        justifyContent: 'space-between',
        [breakpoints.down('sm')]: {
          minHeight: 46,
        },
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        '&.Mui-completed': {
          fontWeight: 800,
          color: '#9794A3',
        },
      },
      label: {
        color: '#9794A3',
        fontWeight: 800,
      },
    },
  },
  MuiStep: {
    styleOverrides: {
      horizontal: {
        paddingLeft: '12px',
        paddingRight: '20px',
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      line: {
        width: '20px',
        borderColor: '#EEEDF2',
      },
    },
  },
};

export default appBarOverrides;
