import { FunctionComponent } from 'react';
import { Box } from '@mui/material';

import env from '../../lib/env';

import EnvRibbon from './EnvRibbon';

/**
 * Renders an overlay with tools to assist developers
 *
 * @note Only visible (`visibility: visible`) elements receive pointer events
 */
const DevOverlay: FunctionComponent = () => (
  env.feature.DEV_OVERLAY
    ? (
      <Box
        sx={
          {
            inset: 0,
            overflow: 'clip',
            pointerEvents: 'visible',
            position: 'fixed',
            visibility: 'hidden',
            zIndex: 1450,
            '& *': {
              zIndex: 1450,
            },
          }
        }
      >
        <EnvRibbon />
      </Box>
    )
    : null
);

export default DevOverlay;
