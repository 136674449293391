import palette from './palette';

const typographyOverrides = {
  MuiTypography: {
    styleOverrides: {
      root: {
        '& a': {
          color: palette.text.primary,
          textDecoration: 'underline',
          fontWeight: 500,
          fontSize: 'inherit',
        },
        '& strong': {
          fontWeight: 900,
        },
        '& em': {
          fontWeight: 800,
          fontStyle: 'normal',
        },
        '& span': {
          fontWeight: 500,
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        fontSize: 14,
        lineHeight: '18.2px',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 12,
        lineHeight: '16.8px',
        marginTop: 0,
      },
      arrow: {
        color: palette.text.primary,
      },
      tooltipArrow: {
        display: 'block !important',
        backgroundColor: palette.text.primary,
        padding: '7px 12px 10px',
      },
      popper: {
        zIndex: 1099,
        '&[data-popper-placement*="bottom"] div.MuiTooltip-tooltip': {
          marginTop: 0,
        },
        '&[data-popper-placement*="top"] div.MuiTooltip-tooltip': {
          display: 'none',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        fontSize: 16,
        fontWeight: 800,
        lineHeight: '20.8px',
        borderRadius: 6,
        marginLeft: 7,
        '& span': {
          fontWeight: 800,
        },
      },
      colorPrimary: {
        backgroundColor: palette.blue.light,
        color: palette.purple.bright,
      },
    },
  },
};

export default typographyOverrides;
