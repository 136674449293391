import { Region } from '../../../../types/location';
import { backendAuthenticatedAxios } from '../..';

import { AxiosResponse } from 'axios';

const getLocationPreferences = async (): Promise<AxiosResponse<Region[]>> => {
  return await backendAuthenticatedAxios.get('/staff/sponsorship-opportunities/opportunity-linked-locations');
};

export default getLocationPreferences;
