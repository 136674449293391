import { FunctionComponent, useCallback } from 'react';
import Image from 'next/image';
import { Box, Step, StepConnector, StepIconProps, StepLabel, Stepper, styled } from '@mui/material';

import palette from '../../styles/palette';
import { Step as StepType, StepsRecord } from '../../types/Steps';

import checkPic from '../../public/checkbox/check-primary.svg';

export interface ProgressProps {
  steps: StepsRecord;
  activeStep: number;
  connectorOptions?: { opacity: number };
  orientation?: 'horizontal' | 'vertical';
  showIncompleteSteps?: boolean;
  checkActiveStep?: boolean;
  incompleteStepsBold?: boolean;
  activeStepBold?: boolean;
}

export interface StyledProgressProps extends ProgressProps {
  styles?: any;
}

const Progress: FunctionComponent<StyledProgressProps> = ({
  activeStep = -1,
  connectorOptions = null,
  orientation = 'horizontal',
  showIncompleteSteps = false,
  incompleteStepsBold = true,
  activeStepBold = true,
  checkActiveStep = false,
  steps,
  styles,
}) => {
  const connector = connectorOptions ? <StepConnector sx={{ opacity: connectorOptions.opacity }} /> : null;

  const stepStyles = useCallback((step: StepType) => {
    const boldStyle = { fontWeight: 800 };

    const stepStyle = {
      color: '#9794A3',
      lineHeight: '18.2px',
      ...(styles?.step ?? {}),
    };

    const activeStyle = {
      color: '#051E27',
      lineHeight: '18.2px',
      ...(styles?.activeStep ?? {}),
    };

    let finalStyles = stepStyle;

    if (step.key === activeStep) {
      finalStyles = { ...finalStyles, ...activeStyle, ...(activeStepBold ? boldStyle : {}) };

      return finalStyles;
    }

    if (step.key > activeStep && incompleteStepsBold) {
      finalStyles = { ...finalStyles, ...(incompleteStepsBold ? boldStyle : {}) };

      return finalStyles;
    }

    return finalStyles;
  }, [ styles, activeStep, incompleteStepsBold, activeStepBold ]);

  return (
    <Stepper activeStep={activeStep} connector={connector} orientation={orientation}>
      {
        Object.values(steps).map((step) => (
          <Step key={step.key}>
            <StepLabel StepIconComponent={
              (props) => (
                <StepIcon
                  {...props}
                  completed={step.key < activeStep || (checkActiveStep && step.key === activeStep)}
                  showIncompleteSteps={showIncompleteSteps}
                  styles={styles}
                />
              )
            }
            >
              <Box sx={stepStyles(step)}>
                {step.title}
              </Box>
            </StepLabel>
          </Step>
        ))
      }
    </Stepper>
  );
};

export type StepIconCustomProps = { showIncompleteSteps: boolean; styles: any };
export type StepIconComponentProps = StepIconProps & StepIconCustomProps;

const StepIcon: FunctionComponent<StepIconComponentProps> = ({
  completed,
  showIncompleteSteps,
  styles,
}) => {
  const stepIconBaseStyle = {
    alignContent: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: '22px',
    justifyContent: 'center',
    marginRight: '8px',
    width: '22px',
  };

  const checkedStyle = {
    backgroundColor: palette.primary.pastelLight,
  };

  const incompleteStyle = {
    backgroundColor: palette.grey.light,
  };

  return (
    <div>
      {
        completed
          ? (
            <div style={{ ...stepIconBaseStyle, ...checkedStyle, ...(styles?.checkWrapper ?? {}) }}>
              <Image alt='check' src={checkPic} />
            </div>

          )
          : showIncompleteSteps
            ? (
              <div style={{ ...stepIconBaseStyle, ...incompleteStyle, ...(styles?.incompleteWrapper ?? {}) }} />
            )
            : null
      }
    </div>
  );
};

export default styled(Progress)<StyledProgressProps>(({ styles }) => ({
  '& .MuiStep-root': {
    fontWeight: 800,
    lineHeight: '18.2px',
    color: '#9794A3',
    ...(styles?.step ?? {}),

    '& .Mui-active': {
      color: '#051E27',
      ...(styles?.activeStep ?? {}),
    },
  },
}));
