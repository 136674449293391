import React, { FunctionComponent } from 'react';
import { Alert, Snackbar } from '@mui/material';

import useAlert from '../../hooks/useAlert';

const AlertNotification: FunctionComponent = () => {
  const { alertStatus, closeAlert } = useAlert();

  return (
    <Snackbar
      ClickAwayListenerProps={{ onClickAway: () => null }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={alertStatus?.autoHideDuration}
      onClose={closeAlert}
      open={alertStatus?.open}
    >
      <Alert onClose={closeAlert} severity={alertStatus?.severity} variant='filled'>
        {alertStatus?.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertNotification;
