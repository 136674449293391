// Define brand information
const altLogoImg = 'Clasp logo';
const BRAND_COMPANY_NAME = 'Clasp';
const FULL_COMPANY_NAME = 'Clasp';
const BRAND_COMPANY_DOMAIN = 'clasp.com';
const LEGAL_NAME = 'Clasp Inc.';

// Export images and brand information
export {
  altLogoImg,
  BRAND_COMPANY_DOMAIN,
  BRAND_COMPANY_NAME,
  FULL_COMPANY_NAME,
  LEGAL_NAME,
};
