import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

export interface UpdateMailingAddressInfo {
    address_line: string;
    address_detail: string | null;
    city: string;
    state: string;
    zip: string;
}

const updateMailingAddressInfo = async (mailingAddressData: UpdateMailingAddressInfo): Promise<AxiosResponse<void>> =>
  backendAuthenticatedAxios.patch<void>('/common-application-profile/update-mailing-address-info', mailingAddressData);

export default updateMailingAddressInfo;
