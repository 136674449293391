import { backendAuthenticatedAxios } from '../../lib/backend';
import ServerSideAutocomplete from '../../lib/recoil/ServerSideAutocomplete';
import { UsState } from '../../types/eligibility';

/**
 * Manages state associated with the Autocomplete component for UsStates
 */
const UsStateAutocomplete = new ServerSideAutocomplete<UsState>('us-state', async (query) => {
  if (query.length) {
    const response = await backendAuthenticatedAxios.get<UsStateApiResponse>(`us-state/?q=${query}`);

    return response.data.results;
  }

  return [];
});

interface UsStateApiResponse {
  results: UsState[];
}

export default UsStateAutocomplete;
