import { backendAuthenticatedAxios } from '..';

/**
 * Obtains a guest token to authorize requests to Preset's dashboard embed API
 */
const fetchTrueworkSessionToken = async (): Promise<string> => {
  const response = await backendAuthenticatedAxios.post<TrueworkSessionTokenResponse>(
    'truework/session-token',
  );

  return response.data.token;
};

interface TrueworkSessionTokenResponse {
  id: string;
  token: string;
}

export default fetchTrueworkSessionToken;
