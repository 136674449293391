import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import { Person } from '@mui/icons-material';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import { useLogout } from '../../../hooks/useLogout';
import routes from '../../../lib/routes';
import palette from '../../../styles/palette';

interface MenuItem {
  icon: JSX.Element;
  onClick: () => void;
}

type AccountOptionsDropdownList = Record<string, MenuItem>;

const AccountOptionsDropdown: FunctionComponent = () => {
  const router = useRouter();
  const logout = useLogout();

  const handleLogout = () => {
    router.push(routes.internal.homepage.url)
      .catch((err) => {
        console.error(err);
        router.push('/');
      }).finally(() => {
        logout();
      });
  };

  /**
   * @note Add more to this list as we add more CTAs
   */
  const options: AccountOptionsDropdownList = {
    Profile: {
      icon: <Person color={'disabled'} fontSize={'small'} />,
      onClick: () => {
        router.push(routes.internal.profile.url);
      },
    },
    Logout: {
      icon: <LogoutRoundedIcon color={'disabled'} fontSize={'small'} />,
      onClick: () => {
        handleLogout();
      },
    },
  };

  return (
    <>
      <FormControl focused={false} sx={{ width: 100, marginTop: 2 }}>
        {/* @todo: Change account label to reflect current user's name */}
        <InputLabel id='account-dropdown-label'>Account</InputLabel>
        <Select
          id='account-options'
          labelId='demo-simple-select-helper-label'
        >
          {
            Object.keys(options).map((key)  => {
              return (
                <MenuItem
                  key={key}
                  onClick={options[key].onClick}
                  sx={
                    {
                      color: 'white',
                    }
                  }
                >
                  <Box mr={0.5}>
                    {options[key].icon}
                  </Box>
                  <Typography sx={{ color: palette.grey.neutral, fontWeight: 'bold' }} variant={'body1'}>
                    {key}
                  </Typography>

                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    </>
  );
};

export default AccountOptionsDropdown;
