/* eslint-disable no-useless-escape */
/* Disabling to maintain current regex functionality and avoid a build failure */
const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_REQUIREMENTS = 'Password Requirements:';

const PASSWORD_ERROR_HELPER_RULES = {
  MINIMUM_8_CHARS: 'Minimum eight characters',
  MINIMUM_ONE_LOWERCASE_ALPHABETICAL_CHAR: 'At least one lowercase alphabetical character',
  MINIMUM_ONE_UPPERCASE_ALPHABETICAL_CHAR: 'One uppercase alphabetical character',
  ONE_NUMBER_AND_ONE_SPECIAL_CHARACTER: 'One number, and one special character',
  PASSWORD_AND_CONFIRMATION_MUST_MATCH: 'Password and password confirmation must match',
};
const PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

/**
 * Matches a string with a valid email address based on RFC 5322 (https://datatracker.ietf.org/doc/html/rfc5322#section-3.4.1)
 *
 * @note The local-part is a string of characters that precedes the "@" symbol and identifies the recipient of the email message.
 * The local-part may contain letters, digits, and certain special characters, such as for example dot (".") and hyphen ("-").
 * Email address local-part standard follows:
 *  @remark ^[-!?'{}*\/&#%`^+=|~$\w] which matches the first character of the email address.
 *  It can be any alphanumeric character, or any of these special characters: -!?'{}*\/&#%`^+=|~$
 *  @remark (\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])* which matches zero or more characters that follow the first character.
 *  These can be any alphanumeric character or any of these special characters: .-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~$
 *
 * @note Email address must have only one @ diving the local-part and the domain part following:
 *  @remark @ Match the "@" symbol
 *
 * @note The domain part is a string of characters that follows the "@" symbol and identifies the domain that hosts the recipient's email service.
 * The domain part may contain letters, digits, and hyphens. Email address domain standard follows:
 *  @remark [a-zA-Z0-9] which matches the first character of the domain name. It can be any alphanumeric character.
 *  @remark (-*\.?[a-zA-Z0-9])+ which matches one or more characters that follow the first character of the domain name.
 *  These can be any alphanumeric character, and can also include a period (but only if it's not the first or last character of the domain name).
 *
 * @note After the domain part the address may include subdomains separated by periods ("."), must commonly end with a top-level domain (TLD), such as ".com" or ".org".
 * Top level domain (TLD) standard follows:
 *  @remark \.[a-zA-Z] which matches the top-level domain name, which consists of a period followed by one or two letters.
 *  @remark (-?[a-zA-Z0-9])+ which matches any additional characters that may be part of the domain name (e.g., subdomains).
 *  These can be any alphanumeric character, and can also include a hyphen (but only if it's not the first or last character of the domain name).
 *  @remark $ which indicates the end of the string
 *
 * Source: https://github.com/manishsaraan/email-validator
 */
const EMAIL_REGEXP = /^[-!?'{}*\/&#%`^+=|~$\w](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
const GPA_REGEXP = /^[0-4]\.\d\d?$/;

/**
 * Matches a string with a valid partial phone number
 *
 * @note US numbers can be up to 10 digits
 *
 * @note International phone numbers can be up to 17 digits long,
 *  but must begin with a '+'
 */
const PHONE_PARTIAL_REGEXP = /(^\+?$)|(^\+\d{1,17}$)|(^(\+?1)?\d{1,10}$)/;

/**
 * Matches a string with a valid phone number
 *
 * ---
 * ## US & Canada
 * - No country code or `+1`
 * - Ten digits after country code
 *
 * ### Area codes
 *
 * #### Includes
 * - Three digits
 * - `[2–9]` for the first digit
 * - `[0–8]` for both the second digit (`9` is reserved for future expansion)
 * - `[0–9]` for both the third digit
 *
 * #### Excludes
 * - Unused
 * ```
 * 555
 * ```
 * - US government
 * ```
 * 710
 * ```
 * - Toll-free
 * ```
 * (800|822|833|844|855|866|877|880|881|882|883|884|885|886|887|888|889)
 * ```
 * - Premium call services
 * ```
 * 900
 * ```
 *
 * ### Exchange code
 * - Three digits
 * - `[2–9]` for the first digit
 * - `[0–9]` for both the second and third digits
 *
 * ### Line number
 * - `[0-9]` for exactly four digits
 *
 * ---
 * ## International (not US or Canada)
 * - Must begin with a `+` followed by country code:
 * ```
 * (999|998|997|996|995|994|993|992|991|990|979|978|977|976|975|974|973|972|971|970|969|968|967|966|965|964|963|962|961|960|899|898|897|896|895|894|893|892|891|890|889|888|887|886|885|884|883|882|881|880|879|878|877|876|875|874|873|872|871|870|859|858|857|856|855|854|853|852|851|850|839|838|837|836|835|834|833|832|831|830|809|808|807|806|805|804|803|802|801|800|699|698|697|696|695|694|693|692|691|690|689|688|687|686|685|684|683|682|681|680|679|678|677|676|675|674|673|672|671|670|599|598|597|596|595|594|593|592|591|590|509|508|507|506|505|504|503|502|501|500|429|428|427|426|425|424|423|422|421|420|389|388|387|386|385|384|383|382|381|380|379|378|377|376|375|374|373|372|371|370|359|358|357|356|355|354|353|352|351|350|299|298|297|296|295|294|293|292|291|290|289|288|287|286|285|284|283|282|281|280|269|268|267|266|265|264|263|262|261|260|259|258|257|256|255|254|253|252|251|250|249|248|247|246|245|244|243|242|241|240|239|238|237|236|235|234|233|232|231|230|229|228|227|226|225|224|223|222|221|220|219|218|217|216|215|214|213|212|211|210|98|95|94|93|92|91|90|86|84|82|81|66|65|64|63|62|61|60|58|57|56|55|54|53|52|51|49|48|47|46|45|44|43|41|40|39|36|34|33|32|31|30|27|20|7)
 * ```
 * - Between 4 and 14 digits after country code
 */
const PHONE_REGEXP = /^(\+((99|97|96|89|88|87|85|83|80|69|68|67|59|50|42|38|37|35|29|28|26|25|24|23|22|21)\d|98|95|94|93|92|91|90|86|84|82|81|66|65|64|63|62|61|60|58|57|56|55|54|53|52|51|49|48|47|46|45|44|43|41|40|39|36|34|33|32|31|30|27|20|7)\d{4,14}|(\+?1)?(?!(555|710|800|822|833|844|855|866|877|880|881|882|883|884|885|886|887|888|889|900))([2-9][0-8]\d)([2-9]\d{2})(\d{4}))$/;

const notNullOrEmpty = (value: string | null) => {
  return value !== null && value !== '';
};

export {
  EMAIL_REGEXP,
  GPA_REGEXP,
  notNullOrEmpty,
  PASSWORD_ERROR_HELPER_RULES,
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEXP,
  PASSWORD_REQUIREMENTS,
  PHONE_PARTIAL_REGEXP,
  PHONE_REGEXP,
};
