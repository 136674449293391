import { FunctionComponent } from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface ExternalWidgetModalProps {
  height?: number | 'auto';
  width?: number;
  isLoading?: boolean;
}

const ExternalWidgetModal: FunctionComponent<ExternalWidgetModalProps> = ({
  children,
  height = 'auto',
  width = 'auto',
  isLoading = false,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      height={height}
      width={width}
    >
      {children}
      {
        isLoading && (
          <Box
            alignItems='center'
            bgcolor='rgba(255, 255, 255, 0.7)'
            bottom={0}
            display='flex'
            justifyContent='center'
            left={0}
            position='absolute'
            right={0}
            top={0}
          >
            <CircularProgress sx={{ margin: 0 }} />
          </Box>
        )
      }
    </Box>
  );
};

export default ExternalWidgetModal;
