import { createTheme } from '@mui/material';

import accordionOverrides from './accordionOverrides';
import appBarOverrides from './appBarOverrides';
import buttonOverrides from './buttonOverrides';
import cardOverrides from './cardOverrides';
import datepickerOverrides from './datePickerOverrides';
import inputOverrides from './inputOverrides';
import palette from './palette';
import sidebarOverrides from './sidebarOverrides';
import sliderOverrides from './sliderOverrides';
import stepperOverrides from './stepperOverrides';
import tableOverrides from './tableOverrides';
import typography from './typography';
import typographyOverrides from './typographyOverrides';

/**
 * @todo
 * do not use fontSize and other props in overrides section, instead bind it to typography classes from the theme
 */
const theme = createTheme({
  palette,
  typography,
  shape: {
    borderRadius: 10,
  },
  components: {
    ...typographyOverrides,
    ...buttonOverrides,
    ...inputOverrides,
    ...appBarOverrides,
    ...datepickerOverrides,
    ...sidebarOverrides,
    ...tableOverrides,
    ...stepperOverrides,
    ...sliderOverrides,
    ...accordionOverrides,
    ...cardOverrides,
  },
});

export default theme;
