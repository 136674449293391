/**
 * This URL Param controller manages search queries on a URL by adding and removing them from a URL dynamically
 * It needs tracking/searching fields to be predfined, as well as a storage namespace to store
 * search/tracking parameter data.
 */
const urlParamController = {

  trackingParams: [ 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content' ],
  storageName: 'utmData',
  originTrackerName: 'originURL',
  referralTrackerName: 'referralURL',

  hasTrackParamData: function(url: string): boolean {
    const formattedUrl = new URL(url);
    const searchParams = new URLSearchParams(formattedUrl.search);

    return this.trackingParams.some((el: string) => searchParams.get(el));
  },

  /**
   * This function takes in a URL, converts to a URL instance and checks if there are UTM parameters.
   * If there are UTM parameters that are in trackingParams, bundle and store them in session storage.
   */
  storeUrlData: function(url: string): void  {
    const utmData = new URLSearchParams(new URL(url).search);
    const trackingData: Record<string, string> = {};

    this.trackingParams.forEach((param: string) => {
      const parsedUTMValue = utmData.get(param);
      parsedUTMValue !== null && parsedUTMValue !== undefined ? trackingData[param] = parsedUTMValue : null;
    });
    sessionStorage.setItem(this.storageName, JSON.stringify(trackingData));
  },

  /**
   * Retrieve the original URL that initiated the Portal pre-app form.
   */
  getOriginURL: function(): string | null {
    return sessionStorage.getItem(this.originTrackerName);
  },

  /**
   * Retrieve the original URL that initiated the Portal pre-app form.
   */
  getReferringURL: function(): string | null {
    return sessionStorage.getItem(this.referralTrackerName);
  },

  /**
   * This function handles the retrieval and formatting of stored UTM data if it exists.
   */
  getStorageData: function(): Record<string, string> | null {
    const storageData = sessionStorage.getItem(this.storageName);
    let utmData: Record<string, string> | null;
    storageData ? utmData = JSON.parse(storageData) : utmData = null;

    return utmData;
  },

  /**
   * This takes in a URL, and returns the URL with tracking data appended to it
   */
  updateUrlWithUtm: function(baseUrl: string): string {
    const baseUrlObj = new URL(baseUrl);
    const searchParams = new URLSearchParams(baseUrlObj.search);
    const utmData = this.getStorageData();

    if (!utmData) {
      return baseUrl;
    }

    this.trackingParams.forEach((param) => {
      utmData[param] ? searchParams.set(param, utmData[param]) : null;
    });
    baseUrlObj.search = searchParams.toString();

    return baseUrlObj.href;
  },

  /**
   * This function deletes all tracking parameters that are defined when initialiazing an instance of this tracker.
   * @returns a url string without any search/tracking fields. Returns null if there is an invalid URL.
   */
  cleanUrl: function(url: string): string | null {
    try {
      const urlObj = new URL(url);
      const searchParams = new URLSearchParams(urlObj.search);

      this.trackingParams.forEach((param) => {
        searchParams.delete(param);
      });
      urlObj.search = searchParams.toString();

      return urlObj.href;
    } catch (error) {
      console.error(error);

      return null;
    }
  },
  /**
   * This function strips a url of all search params and updates the URL the browser
   * @param url
   * @returns
   */
  removeUrlQueryParams: function(url: string): void {
    const cleanedUrl = urlParamController.cleanUrl(url);

    if (cleanedUrl) {
      window.history.replaceState(null, '', cleanedUrl);
      console.log('Removed query from URL');

      return;
    }
    console.log('Error in removing query from URL');
  },
  /**
   * If there is utm data then clean the url, if the utm data isn't stored, then store it.
   */
  handlePageLoad: function(): void {
    const url = window.location.href;
    sessionStorage.setItem(this.originTrackerName, url);
    sessionStorage.setItem(this.referralTrackerName, document.referrer);

    if (this.hasTrackParamData(url)) {
      const formattedUrl = this.cleanUrl(window.location.href);
      formattedUrl !== null ? window.history.replaceState('', document.title, formattedUrl) : null;
      this.getStorageData() === null ? this.storeUrlData(url) : null;
    }
  },

  /**
   * If the utm data isn't stored, then store it.
   */
  handleSessionStorage: function(): void {
    const url = window.location.href;
    sessionStorage.setItem(this.originTrackerName, url);
    sessionStorage.setItem(this.referralTrackerName, document.referrer);

    if (this.hasTrackParamData(url)) {
      this.getStorageData() === null ? this.storeUrlData(url) : null;
    }
  },
};

export default urlParamController;
