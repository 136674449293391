import React, { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import { Grid } from '@mui/material';

import { eligibilitySteps } from '../../constants/eligibility';
import { eligibilityActiveStep } from '../../state/eligibilityProgress';

import Progress from './Progress';

const ElegibilityProgressBar: FunctionComponent = () => {
  const router = useRouter();
  const connectorOptions = { opacity: router.pathname.includes('eligibility') ? 1 : .3 };
  const activeStep = useRecoilValue(eligibilityActiveStep);

  return (
    <Grid mt={1}>
      <Progress
        activeStep={activeStep}
        connectorOptions={connectorOptions}
        steps={eligibilitySteps}
        styles={{ step: { fontWeight: 800 }}}
      />
    </Grid>
  );
};

export default ElegibilityProgressBar;
