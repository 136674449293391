import { PortalChangeRequest } from '../../../../types/portalChangeRequest';
import { backendAuthenticatedAxios } from '../..';
import { BulkActionResponse } from '../../types';

import { AxiosResponse } from 'axios';

type GenerateFinwiseLoanTransactionResponse = BulkActionResponse<PortalChangeRequest>;

const generateFinwiseLoanTransactions = async (applicationIds: string[]): Promise<AxiosResponse<GenerateFinwiseLoanTransactionResponse>> => {
  const reqBody = { 'originator_app_ids': applicationIds };

  return await backendAuthenticatedAxios.post<GenerateFinwiseLoanTransactionResponse>('/staff/portal-change-request/generate-loan-transaction', reqBody)
    .catch(() => {
      throw new Error('Failed to Generate Loan Transaction Object');
    });
};

export default generateFinwiseLoanTransactions;
