import { backendAuthenticatedAxios } from '..';

type RepaymentSessionTokenResponse = {
    response: JSON;
    token: string;
};

/**
 * Obtains a session token needed for repayment session initialization
 */
const fetchRepaymentSessionToken = async (): Promise<string> => {
  const response = await backendAuthenticatedAxios.post<RepaymentSessionTokenResponse>(
    'repayment/create-repayment-session-token',
  );

  return response.data.token;
};

export default fetchRepaymentSessionToken;
