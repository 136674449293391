import assert from 'assert';

import {
  atom,
  DefaultValue,
  RecoilState,
  RecoilValueReadOnly,
  selector,
} from 'recoil';

import { sessionAtom } from '../persist';

import Rule, { RuleProps } from './Rule';
import { HelperContent } from './types';

/**
 * Manages state associated with a form input element
 */
class Field<T> {
  /**
   * Checks that raw input is safe to save to state
   */
  checkSafety: IsSafeAction<T>;

  /** Used as the initial value and the reset value */
  defaultValue: T;

  /**
   * Represents whether the input element
   *  should indicate an error to the user
   */
  errorState: RecoilValueReadOnly<boolean>;

  /** Represents helper text for the input element */
  helperTextState: RecoilValueReadOnly<HelperContent>;

  /**
   * Represents whether enough user interaction
   *  has occured to show an error to the user
   */
  #touchedState: RecoilState<boolean>;

  /** List of Rules used to validate this Field */
  rules: Rule<T>[];

  /**
   * Represents whether enough user interaction
   *  has occured to show an error to the user
   *
   * Provides controlled read-write access
   *  to atom `this.#touchedState`
   */
  touchedState: RecoilState<boolean>;

  /**
   * Represents whether this Field is valid,
   *  i.e passes all the rules
   */
  validState: RecoilValueReadOnly<boolean>;

  /** The internal value of this field */
  atom: RecoilState<T>;

  /**
   * Accesses the value of this field
   *
   * Provides controlled read-write access
   *  to atom `this.#valueState`
   */
  valueState: RecoilState<T>;

  /**
   * Decides whether the atom should persist or not.
   */
  persists: boolean;

  /**
   * Constructs a new `Field` instance
   *
   * @example
   *  name = new Field<string>({
   *    defaultValue: '',
   *    key: 'name',
   *    rules: [{...}],
   *    persists: true
   *  })
   */
  constructor({
    checkSafety = () => true,
    defaultValue,
    key,
    rules = [],
    persists = true,
  }: FieldProps<T>) {
    assert.ok(
      checkSafety(defaultValue),
      'Default value is unsafe! \'checkSafety(defaultValue)\' must return true',
    );

    this.defaultValue = defaultValue;
    this.checkSafety = checkSafety;

    // Create the Rules for this Field
    this.rules = rules.map((rule) => new Rule<T>(rule));

    // Decide whether or not to persist the function
    this.persists = persists;

    // Create a private atom to hold value
    this.atom = sessionAtom<T>({
      key: `${key}/formField/#value`,
      default: this.defaultValue,
    }, this.persists);

    // Create the value selector
    this.valueState = selector<T>({
      key: `${key}/formField/value`,
      get: ({ get }) => get<T>(this.atom),
      set: ({ set }, newValue) =>
        // Check that this method is not being run as a reset
        !(newValue instanceof DefaultValue)
          // Set #valueState after cleaning
          && set<T>(
            this.atom,
            (oldValue) =>
              this.makeSafe(newValue, oldValue),
          ),
    });

    // Create a private atom to hold touched state
    this.#touchedState = atom<boolean>({
      key: `${key}/formField/#touched`,
      default: false,
    });

    // Create the touched selector
    this.touchedState = selector<boolean>({
      key: `${key}/formField/touched`,
      get: ({ get }) => get(this.#touchedState),
      set: ({ set }, newValue) =>
        // Check that this method is not being run as a reset
        !(newValue instanceof DefaultValue)
        // #touched = newValue
        && set<boolean>(this.#touchedState, newValue),
    });

    // Create the valid selector
    this.validState = selector<boolean>({
      key: `${key}/formField/valid`,
      get: ({ get }) =>
        // Check if any rules are broken
        this.rules.every((rule) => rule.check(get(this.atom))),
    });

    // Create the error selector
    this.errorState = selector<boolean>({
      key: `${key}/formField/error`,
      get: ({ get }) =>
        // Check if touched and not valid
        get(this.touchedState) && !get(this.validState),
    });

    // Create the helperText selector
    // @todo prioritize errors over warnings
    this.helperTextState = selector<HelperContent>({
      key: `${key}/formField/helperText`,
      get: ({ get }) => {
        // Do not show helper text if untouched
        if (!get(this.#touchedState)) return '';

        // Find the first broken rule
        const brokenRule = this.rules.find((rule) =>
          !rule.check(get(this.valueState)));

        // Return the broken rule's helperText
        return brokenRule
          ? brokenRule.helperText(get(this.valueState))
          : '';
      },
    });
  }

  /**
   * Returns a value that is safe to save to state
   *
   * @returns `oldValue` if
   *  `newValue` is unsafe
   *  `oldValue` is safe
   *
   * @returns `this.defaultValue` if
   *  `newValue` and `oldValue` are both unsafe
   */
  makeSafe(newValue: T, oldValue: T): T {
    return this.checkSafety(newValue)
      ? newValue
      : this.checkSafety(oldValue)
        ? oldValue
        : this.defaultValue;
  }
}

type IsSafeAction<T> = (rawValue: T) => boolean;

export interface FieldProps<T> {

  /**
   * Checks that raw input is safe to save to state
   *
   * @param rawValue
   * @returns true if the `rawValue` should be saved
   * @returns false if the `rawValue` should not be saved
   *
   * See `Form.prototype.makeSafe()` for implementation details
   */
  checkSafety?: IsSafeAction<T>;

  /** Used as the initial value and the reset value */
  defaultValue: T;

  /** Prepended to Recoil state keys -- should be unique */
  key: string;

  /**
   * `Rules` used to validate this field
   *
   * @note Rules are evaluated in sequence,
   *  so the first one to fail will control helperTextState
   */
  rules: RuleProps<T>[];

  /** Decides whether or not the atom should persist */
  persists?: boolean;
}

export default Field;
