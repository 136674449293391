import { FunctionComponent, useState } from 'react';
import { Box, Button, Grid, Tooltip } from '@mui/material';

import useLocalState from '../../hooks/useLocalState';
import env from '../../lib/env';

/**
 * Creates a ribbon that displays the current `env.var.NODE_ENV`
 *
 * Click the ribbon the move it to a different corner
 */
const EnvRibbon: FunctionComponent = () => {
  const [ position, setPosition ] = useLocalState('EnvRibbon/position', 0);
  const [ isAnimated, setIsAnimated ] = useState(false);

  const handleClick = () => {
    if (!isAnimated) {
      setIsAnimated(true);

      setTimeout(() => {
        setIsAnimated(false);
        setPosition((prev) => ++prev % 4);
      }, 1000);
    }
  };

  return (
    <Box
      sx={
        {
          height: '100%',
          position: 'relative',
          transition: 'all 1s',
          visibility: 'hidden',
          width: '100%',
          ...(isAnimated
            ? {
              transform: 'scale(1.4)',
              filter: 'blur(5)',
            }
            : {}
          ),
        }
      }
    >
      <Grid
        container
        justifyContent='center'
        sx={
          {
            display: 'flex',
            overflow: 'visible',
            position: 'absolute',
            visibility: 'visible',
            width: '120px',
            ...[
              topRightStyles,
              bottomRightStyles,
              bottomLeftStyles,
              topLeftStyles,
            ][position],
          }
        }
      >
        <Grid
          item
          minWidth='300%'
        >
          <Grid
            alignItems='center'
            container
            direction='column'
          >
            <Tooltip
              PopperProps={
                {
                  style: {
                    paddingTop: '2em',
                    zIndex: 1500,
                  },
                }
              }
              describeChild
              disableFocusListener
              followCursor
              title='Click to dispel'
            >
              <Button
                TouchRippleProps={
                  {
                    style: {
                    // Force ripple to center vertically
                      marginTop: '-25%',
                    },
                  }
                }
                onClick={handleClick}
                sx={
                  {
                    bgcolor: colorMap[env.var.NODE_ENV] + '.light',
                    color: colorMap[env.var.NODE_ENV] + '.contrastText',
                    letterSpacing: 2,
                    textTransform: 'uppercase',
                    px: 10,
                    ':hover': {
                      bgcolor: colorMap[env.var.NODE_ENV] + '.dark',
                    },
                    ':focus': {
                      bgcolor: colorMap[env.var.NODE_ENV] + '.light',
                    },
                    overflow: 'clip',
                  }
                }
                variant='contained'
              >
                {env.var.NODE_ENV}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const topRightStyles = {
  top: 0,
  right: 0,
  transform: 'translate(-5%) rotate(30deg)',
  transformOrigin: 'top left',
};

const bottomRightStyles = {
  bottom: 0,
  right: 0,
  transform: 'translate(-5%) rotate(-30deg)',
  transformOrigin: 'bottom left',
};

const bottomLeftStyles = {
  bottom: 0,
  left: 0,
  transform: 'translate(5%) rotate(30deg)',
  transformOrigin: 'bottom right',
};

const topLeftStyles = {
  top: 0,
  left: 0,
  transform: 'translate(5%) rotate(-30deg)',
  transformOrigin: 'top right',
};

const colorMap = {
  development: 'success',
  production: 'error',
  test: 'warning',
};

export default EnvRibbon;
