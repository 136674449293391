import { BorrowerAccountProfile } from '../../../types/borrowerAccountProfile';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const updateAccountNumber = async (loanServicerAccountId: string, accountNumber: string): Promise<AxiosResponse<BorrowerAccountProfile>> => {
  return backendAuthenticatedAxios.patch<BorrowerAccountProfile>(
    `repayment/account/${loanServicerAccountId}/update-account-number`,
    { 'account_number': accountNumber },
  );
};

export default updateAccountNumber;
