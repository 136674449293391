import { GPA_REGEXP } from '../../../constants/validation';
import Form, { Field } from '../../../lib/recoil/Form';
import {
  Cip,
  CredentialLevel,
  EnrollmentStatus, Institution,
  UsCitizenshipStatus,
  UsState,
} from '../../../types/eligibility';

import { isValid, startOfMonth } from 'date-fns';

/**
 * Manages the state of the university eligibility form
 *
 * @note used in `pages/eligibility/university/`
 */
const universityEligibilityForm = new Form({
  fields: {
    // Program step
    credentialLevel: new Field<CredentialLevel | null>({
      key: 'credentialLevel',
      defaultValue: null,
      rules: [
        {
          helperText: 'required',
          pattern: (value) =>
            value !== null,
          severity: 'error',
        },
      ],
    }),
    institution: new Field<Institution | null>({
      key: 'institution',
      defaultValue: null,
      rules: [
        {
          helperText: 'required',
          pattern: (value) =>
            value !== null,
          severity: 'error',
        },
      ],
    }),
    cip: new Field<Cip | null>({
      key: 'cip',
      defaultValue: null,
      rules: [
        {
          helperText: 'required',
          pattern: (value) =>
            value !== null,
          severity: 'error',
        },
      ],
    }),
    graduationDate: new Field<string>({
      key: 'graduationDate',
      defaultValue: new Date().toISOString(),
      rules: [
        {
          helperText: 'must be a valid date',
          pattern: (value) =>
            isValid(new Date(value)),
          severity: 'error',
        },
        {
          helperText: 'must be in the future',
          pattern: (value) =>
            new Date(value) >= startOfMonth(new Date()),
          severity: 'error',
        },
      ],
    }),
    // Funding step
    fundingAmount: new Field<number | null>({
      key: 'fundingAmount',
      defaultValue: null,
      rules: [
        {
          helperText: 'required',
          pattern: (value) =>
            value !== null,
          severity: 'error',
        },
        {
          helperText: 'Must be a positive number',
          pattern: (value) =>
            (value as number) >= 0,
          severity: 'error',
        },
      ],
    }),
    fundingDate: new Field<string>({
      key: 'fundingDate',
      defaultValue: new Date().toISOString(),
      rules: [
        {
          helperText: 'must be a valid date',
          pattern: (value) =>
            isValid(new Date(value)),
          severity: 'error',
        },
        {
          helperText: 'must be in the future',
          pattern: (value) =>
            new Date(value) >= startOfMonth(new Date()),
          severity: 'error',
        },
      ],
    }),
    // About You step
    usCitizenshipStatus: new Field<UsCitizenshipStatus | null>({
      key: 'usCitizenshipStatus',
      defaultValue: null,
      rules: [
        {
          helperText: 'required',
          pattern: (value) =>
            value !== null,
          severity: 'error',
        },
      ],
    }),
    stateOfResidence: new Field<UsState | null>({
      key: 'stateOfResidence',
      defaultValue: null,
      rules: [
        {
          helperText: 'required',
          pattern: (value) =>
            value !== null,
          severity: 'error',
        },
      ],
    }),
    gpa: new Field<string>({
      key: 'gpa',
      defaultValue: '',
      rules: [
        {
          helperText: 'required',
          pattern: (value) =>
            GPA_REGEXP.test(value),
          severity: 'error',
        },
      ],
    }),
    enrollmentStatus: new Field<EnrollmentStatus | null>({
      key: 'enrollmentStatus',
      defaultValue: null,
      rules: [
        {
          helperText: 'required',
          pattern: (value) =>
            value !== null,
          severity: 'error',
        },
      ],
    }),
  },
});

export default universityEligibilityForm;
