import { RepaymentFund } from '../../../types/responseSchema/repaymentPartner';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

type SubmitPaymentRequestProps = {
  repaymentFund: RepaymentFund;
  amount: number;
  customer_id: string;
};

const submitPaymentRequest = async ({
  repaymentFund,
  amount,
  customer_id,
}: SubmitPaymentRequestProps): Promise<AxiosResponse<void>> => {
  const requestData = {
    'partner_id': repaymentFund.partner_id,
    'amount': amount,
    'customer_id': customer_id,
  };

  return await backendAuthenticatedAxios
    .post('/repayment/employer/create-payment-request', requestData);
};

export default submitPaymentRequest;
