import { PortalChangeRequest } from '../../../../types/portalChangeRequest';
import { backendAuthenticatedAxios } from '../..';
import { BulkActionResponse } from '../../types';

import { AxiosResponse } from 'axios';

type ApproveFinwiseLoanTransactionResponse = BulkActionResponse<PortalChangeRequest>;

const approveFinwiseLoanTransactions = async (changeRequestIds: string[]): Promise<AxiosResponse<ApproveFinwiseLoanTransactionResponse>> => {
  const reqBody = {
    'portal_change_request_ids': changeRequestIds,
  };

  // TODO - Better error handling?
  return await backendAuthenticatedAxios.post<ApproveFinwiseLoanTransactionResponse>('/staff/portal-change-request/approve-loan-transaction', reqBody)
    .catch(() => {
      throw new Error('Failed to Approve Loan Transaction Objects');
    });
};

export default approveFinwiseLoanTransactions;
