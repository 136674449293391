import {
  atom,
  AtomOptions,
  RecoilState,
} from 'recoil';

import { recoilPersist } from 'recoil-persist';

/**
 * Creates an atom that persists state to localStorage
 */
const localAtom = <T>(options: AtomOptions<T>): RecoilState<T> =>
  atom<T>({
    ...options,
    effects: [
      // Only use persistAtom in browser
      ...(
        typeof window !== 'undefined'
          ? [recoilPersist({ storage: window.localStorage }).persistAtom]
          : []
      ),
      // User effects
      ...(
        options.effects
          ? options.effects
          : []
      ),
    ],
  });

/**
 * Creates an atom that persists state to sessionStorage
 */
const sessionAtom = <T>(options: AtomOptions<T>, persists = true): RecoilState<T> => {
  const atomOptions = {
    ...options,
    effects: persists
      ? [
        // Only use persistAtom in the browser
        ...(typeof window !== 'undefined'
          ? [recoilPersist({ storage: window.sessionStorage }).persistAtom]
          : []),
        // User effects
        ...(options.effects ? options.effects : []),
      ]
      : [], // No persistence effects if shouldAtomPersist is false
  };

  return atom<T>(atomOptions);
};

export {
  localAtom,
  sessionAtom,
};
