import { backendAnonymousAxios } from '..';

import jwt, { Tokens } from './jwt';

import { AxiosRequestConfig } from 'axios';

/**
 * Configures an axios instance with JWT access token
 *
 * Uses standard JWT schema:
 * ```
 * Authorization: Bearer <token>
 * ```
 */
const authenticateRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  if (!jwt.access) {
    // Don't have JWT, get new ones
    console.log('Initializing with new auth tokens');

    await backendAnonymousAxios.get<Partial<Tokens>>('/account/auth')
      .then(({ data: { access, refresh }}) =>
        jwt.save(access, refresh),
      ).catch(() => {
        console.error('JWT: Failed to repair tokens');

        jwt.clear();
      });
  }

  // Set JWT tokens
  config = {
    ...config,
    'headers': {
      ...(config?.headers ?? {}),
      'common': {
        ...(config?.headers?.common ?? {}),
        'Authorization': `Bearer ${jwt.access}`,
      },
    },
  };

  return config;
};

export default authenticateRequest;
