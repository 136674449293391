import { backendAuthenticatedAxios } from '../..';

const rejectFinwiseLoans = async (applicationIds: string[]): Promise<void> => {
  const reqBody = {
    'originator_app_ids': applicationIds,
  };

  await backendAuthenticatedAxios.post<void>('/staff/cfp/reject-finwise-loan', reqBody)
    .catch(() => {
      throw new Error('Failed to Reject Loan');
    });
};

export default rejectFinwiseLoans;
