import { RepaymentFund } from '../../../types/responseSchema/repaymentPartner';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

type SubmitPaymentToPlatformRequestProps = {
  repaymentFund: RepaymentFund;
  amount: number;
};

const submitPaymentToPlatformRequest = async ({
  repaymentFund,
  amount,
}: SubmitPaymentToPlatformRequestProps): Promise<AxiosResponse<void>> => {
  const requestData = {
    'partner_id': repaymentFund.partner_id,
    'amount': amount,
  };

  return await backendAuthenticatedAxios
    .post('/spinwheel/employer/make-payment-to-platform', requestData);
};

export default submitPaymentToPlatformRequest;
