import { Chip, chipClasses, ChipProps } from '@mui/material';
import { styled } from '@mui/styles';

const LoanServicerConnectedStatusChipComponent = styled(Chip)({
  borderRadius: '30px',
  borderWidth: 0,
  backgroundColor: '#82C093',
  [`& .${chipClasses.label}`]: {
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'uppercase',
    color: 'white',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
});

export const LoanServicerConnectedStatusChip = (props: ChipProps) => (
  <LoanServicerConnectedStatusChipComponent {...props} />
);
