
import approveFinwiseLoans from './approveFinwiseLoans';
import approveFinwiseLoanTransactions from './approveFinwiseLoanTransactions';
import approveFinwisePayoffs from './approveFinwisePayoffs';
import cancelFinwiseLoans from './cancelFinwiseLoans';
import downloadChangeRequestTemplates from './downloadChangeRequestTemplate';
import generateFinwiseLoans from './generateFinwiseLoans';
import generateFinwiseLoanTransactions from './generateFinwiseLoanTransactions';
import generateFinwisePayoffs from './generateFinwisePayoffs';
import generateNACHA from './generateNACHA';
import getChangeRequest from './getChangeRequest';
import getChangeRequests from './getChangeRequests';
import getCustomerFundingProducts from './getCustomerFundingProducts';
import getDisbursements from './getDisbursements';
import getPortalChangeRequests from './getPortalChangeRequests';
import getSponsorshipOpportunities from './getSponsorshipOpportunities';
import handleChangeRequestReview from './handleChangeRequestReview';
import rejectFinwiseLoans from './rejectFinwiseLoans';
import revertPortalChangeRequest from './revertChangeRequest';
import searchDisbursements from './searchDisbursements';
import syncFinwiseLoans from './syncFinwiseLoans';
import syncFinwiseLoanTransactions from './syncFinwiseLoanTransactions';
import syncFinwisePayoffs from './syncFinwisePayoffs';
import updateChangeRequest from './updateChangeRequest';
import updateChangeRequestStatus from './updateChangeRequestStatus';

export const staffBackend = {
  approveFinwiseLoans,
  cancelFinwiseLoans,
  approveFinwiseLoanTransactions,
  approveFinwisePayoffs,
  downloadChangeRequestTemplates,
  generateFinwiseLoans,
  generateFinwiseLoanTransactions,
  generateFinwisePayoffs,
  generateNACHA,
  getChangeRequest,
  getChangeRequests,
  updateChangeRequestStatus,
  updateChangeRequest,
  getCustomerFundingProducts,
  getDisbursements,
  searchDisbursements,
  getPortalChangeRequests,
  handleChangeRequestReview,
  rejectFinwiseLoans,
  revertPortalChangeRequest,
  syncFinwiseLoans,
  syncFinwiseLoanTransactions,
  syncFinwisePayoffs,
  getSponsorshipOpportunities,
};
