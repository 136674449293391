import { FunctionComponent } from 'react';
import Image, { ImageProps } from 'next/image';

import { altLogoImg } from '../../constants/branding';
import { CustomLink } from '../CustomLink';

import brandLogoXs from '../../public/logo/clasp-77.svg';
import brandLogoSm from '../../public/logo/clasp-88.svg';
import brandLogoMd from '../../public/logo/clasp-120.svg';
import brandLogoXl from '../../public/logo/clasp-148.svg';

export interface LogoProps extends Omit<ImageProps, 'src'> {
    href?: string;
    size: 'xs' | 'sm' | 'md' | 'xl';
}

const logoMap = {
  xs: brandLogoXs,
  sm: brandLogoSm,
  md: brandLogoMd,
  xl: brandLogoXl,
};

const Logo: FunctionComponent<LogoProps> = ({
  href,
  size,
  ...props
}) => {
  const { alt, ...restProps } = props;

  const logoImage = logoMap[size] || brandLogoMd;

  return (
    href
      ? (
        <CustomLink href={href}>
          <Image alt={alt ?? altLogoImg} src={logoImage} {...restProps} />
        </CustomLink>
      )
      : (
        <Image alt={alt ?? altLogoImg} src={logoImage} {...restProps} />
      )
  );
};

export default Logo;
