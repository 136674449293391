import { ConnectBankModalButtonStates } from '../types/ConnectBankModalButtonStates';

// Show continue and cancel only when modal first opens
const initialButtonStates : ConnectBankModalButtonStates = {
  showContinue: true,
  showCancel: true,
  showSuccess: false,
};

// Show cancel only when user going through modal steps
const inProgressButtonStates : ConnectBankModalButtonStates = {
  showContinue: false,
  showCancel: true,
  showSuccess: false,
};

// Show success only when user has successfully navigated the modal
const successfulButtonStates : ConnectBankModalButtonStates = {
  showContinue: false,
  showCancel: false,
  showSuccess: true,
};

export { initialButtonStates, inProgressButtonStates, successfulButtonStates };
