import { AlertColor } from '@mui/material';

export type AlertStatus = {
    message: string;
    severity: AlertColor;
    autoHideDuration?: number;
    open: boolean;
};

export const DEFAULT_AUTO_HIDE_DURATION: Record<AlertColor, number | undefined> = {
  success: 4000,
  error: undefined,
  warning: 6000,
  info: 6000,
};

export const DEFAULT_MESSAGES: Record<AlertColor, string> = {
  success: 'Operation Succeeded!',
  error: 'An unexpected error occurred.',
  warning: 'Proceed with caution: There may be potential issues.',
  info: 'This is an informational message.',
};

export const DEFAULT_SEVERITY: AlertColor = 'error';
