import { ApplicationFormAnswers } from '../../../types/applicationAnswer';
import { StudentSponsorshipApplication } from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '../';

import { AxiosResponse } from 'axios';

interface SubmitAnswersAndLocationPreferencesParams {
    sponsorshipApplicationId: string;
    answers: ApplicationFormAnswers;
    locationPreferences: string[];
    notes: string;
}

const submitAnswersAndLocationPreferences = async ({ sponsorshipApplicationId, answers, locationPreferences, notes }: SubmitAnswersAndLocationPreferencesParams): Promise<AxiosResponse<StudentSponsorshipApplication>> => {
  const requestData = { answers, location_preferences: locationPreferences, notes };

  return backendAuthenticatedAxios.post(`/esl-student/${sponsorshipApplicationId}/submit-answers-and-location-preferences`, requestData);
};

export default submitAnswersAndLocationPreferences;
