import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import backend from '../../../lib/backend';
import programQuestionsForm from '../../../state/forms/eslForms/programQuestionsForm';
import { QuestionType } from '../../../types/applicationQuestion';
import { ValidationError } from '../../../types/errors/validation';
import { StudentSponsorshipApplication } from '../../../types/sponsorshipApplication';

/**
 * Interface for the return type of the useProgramQuestionsForm hook
 */
interface UseProgramQuestionsForm { submitForm: () => Promise<StudentSponsorshipApplication> }

/**
 * Custom hook to manage the state and submission logic for the program questions form.
 *
 * @param sponsorshipApplication - The sponsorship application dat
 * @returns An object containing the submitForm handler function
 */
const useProgramQuestionsForm = (
  sponsorshipApplication: StudentSponsorshipApplication | null,
): UseProgramQuestionsForm => {
  // Form Values
  const locationPreferences = useRecoilValue(programQuestionsForm.fields.locations.valueState);
  const answers = useRecoilValue(programQuestionsForm.fields.applicationFormAnswers.valueState);
  const notes = useRecoilValue(programQuestionsForm.fields.notes.valueState);

  const isFormValid = useMemo(() => {
    // Validate location preferences
    if (sponsorshipApplication?.show_location_preferences && !locationPreferences?.length) return false;

    // Validate answers
    return sponsorshipApplication?.opportunity_ordered_questions?.every(({ application_question }) => {
      const { id, required, question_type: questionType } = application_question;
      // If the question is not required, we don't need to validate it
      if (!required) return true;
      const givenAnswer = answers[id];
      // If the question is required and the answer is missing for any question type, the form is invalid
      if (questionType === QuestionType.FREE_TEXT && !givenAnswer?.string_value) return false;
      if (questionType === QuestionType.SINGLE_CHOICE && !givenAnswer?.single_choice_value) return false;
      if (questionType === QuestionType.MULTIPLE_CHOICE && !givenAnswer?.multiple_choice_value?.length) return false;
      // We don't need to strict-check for boolean or ranking questions as they are always answered (false or the first choice by default respectively)
      if (questionType === QuestionType.BOOLEAN || questionType === QuestionType.RANKING) return true;

      return true;
    });
  }, [ sponsorshipApplication?.show_location_preferences, sponsorshipApplication?.opportunity_ordered_questions, locationPreferences?.length, answers ]);

  const submitForm = useCallback(async () => {
    if (!isFormValid) throw new ValidationError();

    const { data: newSponsorshipApplication } = await backend.submitAnswersAndLocationPreferences({
      sponsorshipApplicationId: sponsorshipApplication?.id as string,
      answers,
      locationPreferences: locationPreferences.map(({ id }) => id),
      notes,
    });

    return newSponsorshipApplication;
  }, [ isFormValid, sponsorshipApplication?.id, answers, locationPreferences, notes ]);

  return { submitForm };
};

export default useProgramQuestionsForm;
