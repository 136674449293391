
import { FundingProductTerms } from '../../../types/responseSchema/fundingProductTerms';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const getFundingProductTerms = async (fptUID: string, fptVersion: number): Promise<AxiosResponse<FundingProductTerms | null>> => {
  // Encode Partner name to account for spaces and reduce transformations across interfaces
  return await backendAuthenticatedAxios.get<FundingProductTerms>(`/terms/${fptUID}/${fptVersion}`);
};

export default getFundingProductTerms;
