
import { CommonApplicationProfile } from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const getCommonApplicationProfile = async (): Promise<AxiosResponse<CommonApplicationProfile>> => {
  /**
   * Backend request to get Common Application Profile, uses User on backend.
   */

  return await backendAuthenticatedAxios.get<CommonApplicationProfile>('/common-application-profile/get-common-application-profile')
    .catch(() => {
      throw new Error('No Common Application Profile found');
    });
};

export default getCommonApplicationProfile;
