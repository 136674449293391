import { BorrowerAccountProfile } from '../../../types/borrowerAccountProfile';
import { backendAuthenticatedAxios } from '..';

type SpinwheelUserIdentityDataResponse = {
  liabilities: BorrowerAccountProfile[];
};

/**
 * Fetch customer liabilities from Spinwheel
 */
const fetchSpinwheelUserIdentityData = async ({
  customerId,
}: {
  customerId: string;
}): Promise<BorrowerAccountProfile[] | null> => {
  try {
    const response
        = await backendAuthenticatedAxios.post<SpinwheelUserIdentityDataResponse>(
          '/spinwheel/user/get-identity-data',
          { customer_id: customerId },
        );

    return response.data.liabilities;
  } catch (err) {
    console.log('error getting user data', err);

    // return null, error handling downstream
    return null;
  }
};

export default fetchSpinwheelUserIdentityData;
