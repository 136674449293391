import { FunctionComponent } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { PASSWORD_ERROR_HELPER_RULES, PASSWORD_REQUIREMENTS } from '../../constants/validation';
import palette from '../../styles/palette';

const { MINIMUM_8_CHARS, MINIMUM_ONE_LOWERCASE_ALPHABETICAL_CHAR, MINIMUM_ONE_UPPERCASE_ALPHABETICAL_CHAR, ONE_NUMBER_AND_ONE_SPECIAL_CHARACTER, PASSWORD_AND_CONFIRMATION_MUST_MATCH } = PASSWORD_ERROR_HELPER_RULES;

const PasswordErrorHelper: FunctionComponent = () => {
  return (
    <Box mt={1}>
      <Grid color={palette.error.dark} container spacing={0.5}>
        <Grid item xs={12}>
          <Typography fontWeight='bold'>
            {PASSWORD_REQUIREMENTS}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          • {MINIMUM_8_CHARS}
        </Grid>
        <Grid item xs={12}>
          • {MINIMUM_ONE_LOWERCASE_ALPHABETICAL_CHAR}
        </Grid>
        <Grid item xs={12}>
          • {MINIMUM_ONE_UPPERCASE_ALPHABETICAL_CHAR}
        </Grid>
        <Grid item xs={12}>
          • {ONE_NUMBER_AND_ONE_SPECIAL_CHARACTER}
        </Grid>
        <Grid item xs={12}>
          • {PASSWORD_AND_CONFIRMATION_MUST_MATCH}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PasswordErrorHelper;
