import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import alertStatusAtom from '../state/atoms/alertStatus';
import { AlertStatus, DEFAULT_AUTO_HIDE_DURATION, DEFAULT_MESSAGES, DEFAULT_SEVERITY } from '../types/alerts/alertStatus';

/**
 * Represents a set of utility functions related to managing alerts using Recoil state.
 *
 * @typedef {Object} useAlertUtils
 * @property {AlertStatus | null} alertStatus - The current alert status.
 * @property {function(newAlertStatus: Partial<AlertStatus>): void} setAlert - Sets the alert status.
 * @property {function(): void} closeAlert - Closes the alert.
 */

type useAlertUtils = {
    alertStatus: AlertStatus | null;
    setAlert: (newAlertStatus: Partial<AlertStatus>) => void;
    showSuccessAlert:  (message: string) => void;
    showErrorAlert: (message: string) => void;
    showInfoAlert: (message: string) => void;
    showWarningAlert: (message: string) => void;
    closeAlert: () => void;
};

/**
 * A custom hook for managing alerts using Recoil state.
 * @returns {useAlertUtils} An object containing utility functions.
 */
const useAlert = (): useAlertUtils => {
  const [ alertStatus, setAlertStatus ]  = useRecoilState(alertStatusAtom);

  /**
     * Sets the alert status.
     *
     * @param {Partial<AlertStatus>} newAlertStatus - The new alert status to set.
     */
  const setAlert = useCallback((newAlertStatus: Partial<AlertStatus>) =>
    setAlertStatus({
      message: newAlertStatus?.message ?? DEFAULT_MESSAGES[newAlertStatus?.severity ?? DEFAULT_SEVERITY],
      severity: newAlertStatus?.severity ?? DEFAULT_SEVERITY,
      autoHideDuration: newAlertStatus?.autoHideDuration ?? DEFAULT_AUTO_HIDE_DURATION[newAlertStatus?.severity ?? DEFAULT_SEVERITY],
      open: newAlertStatus?.open ?? true,
    })
  , [setAlertStatus]);

  /**
     *  Show a success alert with its default params
     */
  const showSuccessAlert = useCallback((message: string) => setAlert({ message, severity: 'success' }), [setAlert]);

  /**
     *  Show an error alert with its default params
     */
  const showErrorAlert = useCallback((message: string) => setAlert({ message, severity: 'error' }), [setAlert]);

  /**
     * Show an info alert with its default params
     */
  const showInfoAlert = useCallback((message: string) => setAlert({ message, severity: 'info' }), [setAlert]);

  /**
     * Show a warning alert with its default params
     */
  const showWarningAlert = useCallback((message: string) => setAlert({ message, severity: 'warning' }), [setAlert]);

  /**
   * Clears the alert status.
   */
  const closeAlert = useCallback(() =>
    alertStatus && setAlertStatus({ ...alertStatus, open: false } ), [ alertStatus, setAlertStatus ]);

  return { setAlert, showSuccessAlert, showErrorAlert, showInfoAlert, showWarningAlert, closeAlert, alertStatus };
};

export default useAlert;
