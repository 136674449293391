import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

interface SponsorshipAgreementResponseURL {
  sign_url: string;
}

const generateSponsorshipAgreement = async (sponsorshipApplicationId: string): Promise<AxiosResponse<SponsorshipAgreementResponseURL>> => {
  return backendAuthenticatedAxios.get<SponsorshipAgreementResponseURL>(
    `/esl-student/${sponsorshipApplicationId}/get-sponsorship-agreement`,
  );
};

export default generateSponsorshipAgreement;
