
import Form from '../../../lib/recoil/Form';

import authFields from './authFields';

const signUpForm = new Form({
  fields: {
    address: authFields.address,
    firstName: authFields.firstName,
    emailAddress: authFields.emailAddress,
    lastName: authFields.lastName,
    mobileConsent: authFields.mobileConsent,
    mobilePhoneNumber: authFields.mobilePhoneNumber,
    password: authFields.password,
    passwordConfirmation: authFields.passwordConfirmation,
    termsConsent: authFields.termsConsent,
  },
});

export default signUpForm;
