export type EligibilityStepsTitle =
  'About you' |
  'Your program' |
  'Apply' |
  'Done';

type EligibilityStep = {
  title: EligibilityStepsTitle | '';
  key: number;
};

const eligibilitySteps: Record<string, EligibilityStep> = {
  'yourProgram': {
    title: 'Your program',
    key: 0,
  },
  'aboutYou': {
    title: 'About you',
    key: 1,
  },
  'apply': {
    title: 'Apply',
    key: 2,
  },
  'done': {
    title: '',
    key: 3,
  },
};

export { eligibilitySteps };
