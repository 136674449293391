import { backendAuthenticatedAxios } from '../..';
import { BulkActionResponse } from '../../types';

import { AxiosResponse } from 'axios';

type SyncFinwiseLoans = BulkActionResponse<any>;

const syncFinwiseLoans = async (applicationIds: string[]): Promise<AxiosResponse<SyncFinwiseLoans>> => {
  const reqBody = { 'originator_app_ids': applicationIds };

  return backendAuthenticatedAxios.post<SyncFinwiseLoans>('/staff/cfp/sync-finwise-loan', reqBody)
    .catch(() => {
      throw new Error('Failed to Sync Loan');
    });
};

export default syncFinwiseLoans;
