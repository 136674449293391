import { PortalChangeRequest } from '../../../../types/portalChangeRequest';
import { backendAuthenticatedAxios } from '../..';
import { getFiltersQueryParams, getOrderingQueryParams, getPaginationQueryParams } from '../../pagination';
import { PaginatedResponse } from '../../types';

import { AxiosResponse } from 'axios';
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from 'material-react-table';

type GetPortalChangeRequestsResponse = PaginatedResponse<PortalChangeRequest>;

const getPortalChangeRequests = async (
  searchTerms: string[],
  pagination: MRT_PaginationState,
  columnFilters?: MRT_ColumnFiltersState,
  sorting?: MRT_SortingState,
): Promise<AxiosResponse<GetPortalChangeRequestsResponse>> => {
  // Relative URL
  const relativeUrl = '/staff/portal-change-request/search';

  // Query parameters
  const paginationOptions = getPaginationQueryParams(pagination);
  const orderingOptions = sorting ? getOrderingQueryParams(sorting) : {};
  const filterOptions = columnFilters ? getFiltersQueryParams(columnFilters) : {};

  const queryParams = new URLSearchParams({
    ...paginationOptions,
    ...orderingOptions,
    ...filterOptions,
    search: searchTerms.join(','),
  });

  return backendAuthenticatedAxios.get<GetPortalChangeRequestsResponse>(`${relativeUrl}?${queryParams.toString()}`);
};

export default getPortalChangeRequests;
