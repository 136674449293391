import { FunctionComponent } from 'react';
import { Box, Grid, Toolbar } from '@mui/material';

import AccountOptions from '../input/AccountOptions';

import ElegibilityProgressBar from './ElegibilityProgressBar';
import Logo from './Logo';

interface TopBarProps {
  showProgress: boolean;
  isMobile: boolean;
}

const TopBar: FunctionComponent<TopBarProps> = ({
  showProgress,
  isMobile,
}) => {
  return (
    <Toolbar disableGutters>
      <Box sx={{ position: 'absolute', top: 15 }}>
        <Logo href='/' size={isMobile ? 'xs' : 'sm'} />
      </Box>
      <Grid container justifyContent='center'>
        {
          showProgress && (
            <ElegibilityProgressBar />
          )
        }
      </Grid>
      <AccountOptions />
    </Toolbar>
  );
};

export default TopBar;
