import { PortalChangeRequest } from '../../../../types/portalChangeRequest';
import { backendAuthenticatedAxios } from '../..';

import { AxiosResponse } from 'axios';

const revertPortalChangeRequest = async (
  id: string,
): Promise<AxiosResponse<PortalChangeRequest>> => {
  return backendAuthenticatedAxios.post<PortalChangeRequest>(
    `staff/portal-change-request/revert-portal-change-request/${id}`, { change_request_id: id },
  );
};

export default revertPortalChangeRequest;

