import { FunctionComponent } from 'react';

import { CustomLink } from '../CustomLink';

export type HeaderLinkProps = {
  href: string;
  displayName: string;
};

const HeaderLink: FunctionComponent<HeaderLinkProps> = ({
  href,
  displayName,
}) => {
  return (
    <CustomLink href={href} style={{ textDecoration: 'none', color: 'black' }}>
      {displayName}
    </CustomLink>
  );
};

export default HeaderLink;
