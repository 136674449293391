import { useFeatureFlagPayload } from 'posthog-js/react';

/**
 * useTypedFeatureFlagPayload Hook
 *
 * This custom hook is designed to provide a type-safe way to access the payload of a PostHog feature flag.
 * By leveraging TypeScript's generic types, this hook allows developers to specify the expected payload structure
 * when accessing a feature flag. This reduces the risk of runtime errors and simplifies the process of working with
 * feature flags in a type-safe manner.
 *
 * @param flagKey - The key (identifier) of the PostHog feature flag.
 * @returns The payload associated with the given feature flag, cast to the specified type, or undefined if the feature flag is not enabled or does not have a payload.
 *
 * Example Usage:
 * ```typescript
 * const claspCutoverBannerPayload = useTypedFeatureFlagPayload<ClaspCutoverBannerPayload>('clasp-cutover-banner');
 * if (claspCutoverBannerPayload) {
 *   // Use the payload safely, knowing that it has the correct structure.
 * }
 * ```
 */
export const useTypedFeatureFlagPayload = <T>(flagKey: string): T | undefined => {
  // The hook calls PostHog's useFeatureFlagPayload with the specified flagKey to get the payload.
  return useFeatureFlagPayload(flagKey) as T | undefined;
  // The returned payload is cast to the specified type (T) or undefined if the feature flag is not enabled or does not have a payload.
};
