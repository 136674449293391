import { AdditionalAnswer } from './applicationAnswer';
import { SponsorshipOpportunityQuestion } from './applicationQuestion';
import { Cip, Institution, UsState } from './eligibility';
import { RequireAdditionalFinancingOption } from './eslProfileFinancingForm';
import { LocationPreference } from './location';
import { SponsorshipOpportunity } from './sponsorshipOpportunity';
import { StepsRecord } from './Steps';

// TODO: Remove hardcoded values and replace with actual backend request for TitleIVCredentialLevels
export const degreeTypes = [
  'Undergraduate Certificate',
  'Associate\'s Degree',
  'Bachelor\'s Degree',
  'Post Baccalaureate Certificate',
  'Master\'s Degree',
  'Doctoral Degree',
  'First Professional Degree',
  'Graduate/Professional Certificate',
];

export interface CommonApplicationProfileFinancingInfo {
    additional_financing: RequireAdditionalFinancingOption | null;
    existing_student_loans: boolean;
    private_loan_consent_release: boolean | null;
}

export interface MailingAddressInfo {
    address_line: string | null;
    address_detail: string | null;
    city: string | null;
    state: UsState | null;
    zip: string | null;
}

export interface CommonApplicationProfile extends CommonApplicationProfileFinancingInfo, MailingAddressInfo {
    id: string;
    student_name: string;
    student_first_name: string;
    student_last_name: string;
    student_email: string;
    student_phone_number: string;
    citizenship_status: string;
    institution: Institution;
    expected_graduation_date: string;
    major: Cip;
    current_gpa: string;
    degree_type: string;
}

export enum SponsorshipApplicationStatus {
  NEW_APPLICANT = 'NEW_APPLICANT',
  IN_REVIEW = 'IN_REVIEW',
  ACCEPTED = 'ACCEPTED',
  WAITLISTED = 'WAITLISTED',
  WITHDRAWN = 'WITHDRAWN',
  DECLINED = 'DECLINED',
  COMPLETE = 'COMPLETE',
  DRAFT = 'DRAFT',
  INELIGIBLE = 'INELIGIBLE',
}

export type VisibleSponsorshipApplicationStatus = Exclude<SponsorshipApplicationStatus, SponsorshipApplicationStatus.INELIGIBLE>;

export enum SponsorshipApplicationSubstatus {
    NO_SUBSTATUS = 'NO_SUBSTATUS',

    // IN_REVIEW substatuses
    INTERVIEW_PENDING = 'INTERVIEW_PENDING',
    INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
    INTERVIEW_COMPLETE = 'INTERVIEW_COMPLETE',

    // ACCEPTED substatuses
    PROFILE_INCOMPLETE = 'PROFILE_INCOMPLETE',
    PROFILE_COMPLETE = 'PROFILE_COMPLETE',
    AGREEMENT_GENERATED = 'AGREEMENT_GENERATED',
}

export interface StoredFile {
  id: string;
  name: string;
  url: string;
  size: number;
}

export interface Interview {
  sponsorship_application_id: string;
  interview_invitation_date_time?: string;
  interview_scheduled_date_time?: string;
}

export interface SponsorshipApplicationFiles {
  [key: string]: StoredFile | null;
}

/**
 * Represents the base structure for sponsorship applications, providing common identification and status tracking fields used across various interfaces.
 */
export interface SponsorshipApplicationBase {
    id: string;
    additional_answers: AdditionalAnswer[];
    location_preferences: LocationPreference[];
    opportunity: SponsorshipOpportunity;
    opportunity_display_name: string;
    opportunity_ordered_questions: SponsorshipOpportunityQuestion[];
    partner_name: string;
    partner_uid: string;
    status: SponsorshipApplicationStatus;
    show_location_preferences: boolean;
    substatus: SponsorshipApplicationSubstatus;
    uploaded_files: SponsorshipApplicationFiles;
    interviews?: Interview[];
}

/**
 * Extends the base sponsorship application information specifically for student use, incorporating additional details relevant for form rendering and student interaction flows.
 */
export interface StudentSponsorshipApplication extends SponsorshipApplicationBase, CommonApplicationProfile {
    opportunity_id: string;
    sign_url: string | null;
    show_location_preferences: boolean;
}

/**
 * Provides a comprehensive model for internal management use, merging base application data with extended profile information and additional fields for operational and workflow management.
 */
export interface SponsorshipApplication extends SponsorshipApplicationBase, CommonApplicationProfile {
    allowed_status_transitions: SponsorshipApplicationStatus[];
    submitted_at: string;
    common_files: StoredFile[];
    partner_id: string;
}

/**
 * Provides a light model for retrieving multiple Sponsorship Applications.
 */
export interface LightSponsorshipApplication {
    'id': string;
    'submitted_at': string;
    'student_name': string;
    'institution': Institution;
    'expected_graduation_date': string;
    'major': Cip;
    'status': SponsorshipApplicationStatus;
    'substatus': SponsorshipApplicationSubstatus;
    'partner_name': string;
}

export type StatusColumn = SponsorshipApplicationStatus.NEW_APPLICANT |
SponsorshipApplicationStatus.IN_REVIEW |
SponsorshipApplicationStatus.ACCEPTED |
SponsorshipApplicationStatus.WAITLISTED |
SponsorshipApplicationStatus.DECLINED |
SponsorshipApplicationStatus.WITHDRAWN |
SponsorshipApplicationStatus.COMPLETE;

export const SUBSTATUSES_DISPLAY_NAME: Record<SponsorshipApplicationSubstatus, string> = {
  [SponsorshipApplicationSubstatus.NO_SUBSTATUS]: 'N/A',

  // IN_REVIEW substatuses
  [SponsorshipApplicationSubstatus.INTERVIEW_PENDING]: 'PENDING INTERVIEW',
  [SponsorshipApplicationSubstatus.INTERVIEW_SCHEDULED]: 'INTERVIEW SCHEDULED',
  [SponsorshipApplicationSubstatus.INTERVIEW_COMPLETE]: 'INTERVIEW COMPLETED',

  // ACCEPTED substatuses
  [SponsorshipApplicationSubstatus.PROFILE_INCOMPLETE]: 'PROFILE INCOMPLETE',
  [SponsorshipApplicationSubstatus.PROFILE_COMPLETE]: 'PROFILE COMPLETE',
  [SponsorshipApplicationSubstatus.AGREEMENT_GENERATED]: 'AGREEMENT GENERATED',
};

export const SUBSTATUSES_AS_STEPS: StepsRecord = {
  [SponsorshipApplicationSubstatus.INTERVIEW_PENDING as string]: { key: 0, title: SUBSTATUSES_DISPLAY_NAME[SponsorshipApplicationSubstatus.INTERVIEW_PENDING] },
  [SponsorshipApplicationSubstatus.INTERVIEW_SCHEDULED as string]: { key: 1, title: SUBSTATUSES_DISPLAY_NAME[SponsorshipApplicationSubstatus.INTERVIEW_SCHEDULED] },
  [SponsorshipApplicationSubstatus.INTERVIEW_COMPLETE as string]: { key: 2, title: SUBSTATUSES_DISPLAY_NAME[SponsorshipApplicationSubstatus.INTERVIEW_COMPLETE] },
};

export const SPONSORSHIP_TERMINAL_STATES = [
  SponsorshipApplicationStatus.COMPLETE,
  SponsorshipApplicationStatus.DECLINED,
  SponsorshipApplicationStatus.WITHDRAWN,
];

export const NON_ACTIONABLE_STATUSES = [
  SponsorshipApplicationStatus.DECLINED,
  SponsorshipApplicationStatus.WITHDRAWN,
  SponsorshipApplicationStatus.INELIGIBLE,
];

export enum SponsorshipApplicationFinacingEnum {
    NEEDS_FINANCING = 'NEEDS_FINANCING',
    ONLY_REPAYMENT = 'ONLY_REPAYMENT',
    BLENDED = 'BLENDED',
    UNKNOWN = 'UNKNOWN',
}

export type SponsorshipApplicationFinancing =
  SponsorshipApplicationFinacingEnum.NEEDS_FINANCING |
  SponsorshipApplicationFinacingEnum.ONLY_REPAYMENT |
  SponsorshipApplicationFinacingEnum.BLENDED |
  SponsorshipApplicationFinacingEnum.UNKNOWN;
