import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Backdrop, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { spinwheelInitializeScriptSrc } from '../../../constants/spinwheel';
import { useScript } from '../../../hooks/useScript';
import backend from '../../../lib/backend';
import spinwheelSessionToken from '../../../state/atoms/spinwheelSessionToken';

type SpinwheelProviderProps = {
    children: ReactNode;
};

/**
 * SpinwheelProvider is a React component that serves as a context provider for the Spinwheel functionality.
 * It handles the loading of the Spinwheel script and manages the session token for Spinwheel operations.
 * This component is essential for initializing the Spinwheel environment and ensuring that all child components
 * have access to the Spinwheel functionalities.
 *
 * @component
 * @param {SpinwheelProviderProps} props - The props for the SpinwheelProvider component.
 * @example
 * return (
 *   <SpinwheelProvider>
 *     <YourChildComponent />
 *   </SpinwheelProvider>
 * );
 */

const SpinwheelProvider: FunctionComponent<SpinwheelProviderProps> = ({ children }: SpinwheelProviderProps) => {
  const [ scriptLoaded, scriptError ] = useScript(spinwheelInitializeScriptSrc);
  const [ sessionToken, setSessionToken ] = useRecoilState(spinwheelSessionToken);
  const [ sessionTokenLoading, setSessionTokenLoading ] = useState(!sessionToken);

  /**
   * Fetches a new Spinwheel session token from the backend.
   * Updates the Recoil state with the new token and sets the loading state.
   * In case of an error, logs the error to the console.
   * This function is crucial for initializing and maintaining the session token.
   */
  const refreshToken = useCallback(async () => {
    setSessionTokenLoading(true);

    try {
      const token = await backend.fetchSpinwheelSessionToken();
      if (!token) return console.error('No token given in response');
      setSessionToken(token);
      console.debug('Spinwheel token acquired.');
    } catch (e) {
      // TODO add handler logic on fail, SSP-2138
      console.error('Failed to get Spinwheel Session token', e);
    } finally {
      setSessionTokenLoading(false);
    }
  }, [ setSessionToken, setSessionTokenLoading ]);

  /**
     * Effect for handling script load.
     * Calls fetchToken if the script is successfully loaded and no session token is available
     */
  useEffect(() => {
    // Only call refreshToken when Spinwheel script is loaded and sessionToken is not set
    if (scriptLoaded && !sessionToken) refreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded]);

  /**
     * Effect for handling script load success.
     * Logs if the Spinwheel script succeed to load.
     */
  useEffect(() => {
    if (scriptLoaded) console.debug('Spinwheel script loaded successfully');
  }, [scriptLoaded]);

  /**
     * Effect for handling script load errors.
     * Logs an error if the Spinwheel script fails to load.
     */
  useEffect(() => {
    if (scriptError) console.error('Error loading Spinwheel script');
  }, [scriptError]);

  if (!scriptLoaded || sessionTokenLoading || !sessionToken) return (
    <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Box alignItems='center' display='flex' flexDirection='column' height='100%' justifyContent='center'>
        <Typography fontWeight='bold' mb='2rem' variant='h6'>Loading student loan connector...</Typography>
        <CircularProgress color='inherit' />
      </Box>
    </Backdrop>
  );

  return <>{children}</>;
};

export default SpinwheelProvider;
