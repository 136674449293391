// Any constants specific to spinwheel can be stored here.

export const spinwheelInitializeScriptSrc = 'https://cdn.spinwheel.io/dropin/v1/dim-initialize.js';

export enum RepaymentPlatformConnectionStatusEnum {
  NOT_CONNECTED = 'NOT_CONNECTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export type RepaymentPlatformConnectionStatus = `${RepaymentPlatformConnectionStatusEnum}` | '' ;
