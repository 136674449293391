import parseEnvBoolean from './parseEnvBoolean';

/**
 * Parses the given feature flag from the environment
 *
 * Uses `false` as a fallback when environment flag is missing
 *
 * @param envKey Key of the env var -- used only for debugging
 * @param envValue Value of the env var in the form `process.env.ENV_KEY`
 */
const parseEnvFeature = (
  envKey: string,
  envValue: string | undefined,
): boolean => parseEnvBoolean(envKey, envValue, false);

export default parseEnvFeature;
