import { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';
import { Backdrop, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import applicationBackdropState from '../../state/atoms/applicationBackdropState';

const ApplicationBackdrop: FunctionComponent = () => {
  const backdropOpen  = useRecoilValue(applicationBackdropState);
  if (!backdropOpen) return null;

  return (
    <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Box alignItems='center' display='flex' flexDirection='column' height='100%' justifyContent='center'>
        <Typography fontWeight='bold' mb='2rem' variant='h6'>Loading...</Typography>
        <CircularProgress color='inherit' />
      </Box>
    </Backdrop>
  );
};

export default ApplicationBackdrop;
