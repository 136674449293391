import { backendAuthenticatedAxios } from '../../index';

/**
 * Asynchronously download change request templates for a list of change requests.
 *
 * This function sends a POST request to the backend API to download templates for the specified
 * change requests.
 *
 * @param {number[]} changeRequestIds - An array of change request IDs for which templates are to be downloaded.
 * @returns {Promise<any>} A promise that resolves when the download request is complete.
 */
const downloadChangeRequestTemplates = async (changeRequestIds: number[]) => {
  const requestData = {
    'change_request_ids': changeRequestIds,
  };

  return backendAuthenticatedAxios.post(
    '/staff/portal-change-request/download-templates', requestData, { responseType: 'arraybuffer' },
  );
};

export default downloadChangeRequestTemplates;
