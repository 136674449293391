import {
  QuoteRequestResponse,
} from '../../../types/responseSchema/quoteRequest';
import { backendAuthenticatedAxios } from '..';

import { AxiosResponse } from 'axios';

const getLatestQuoteRequest = async (customerId: string): Promise<AxiosResponse<QuoteRequestResponse | null>> => {
  return await backendAuthenticatedAxios.get<QuoteRequestResponse | null>(`/customer/${customerId}/quote/latest/`);
};

export default getLatestQuoteRequest;
