
import { SponsorshipApplication } from '../../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '../..';

import { AxiosResponse } from 'axios';

const getPartnerSponsorshipApplication = async (
  applicationId: string,
): Promise<AxiosResponse<SponsorshipApplication>> => {
  /**
   * Backend request to get Sponsorship Application using an application ID.
   */
  return await backendAuthenticatedAxios.get<SponsorshipApplication>(`/sponsorship-applications/${applicationId}`)
    .catch(() => {
      throw new Error('No Sponsorship Applications found');
    });
};

export default getPartnerSponsorshipApplication;
