// TODO Remove Spinwheel on SSP-2840
export enum REPAYMENT_MODAL_STEPS {
    LOAN_SERVICES_LOGIN = 'loan-servicers-login',
    KBA_VERIFICATION = 'kba-verification',
    SELECT_LOAN_SERVICER_ACCOUNT = 'select-loan-servicer-account',
    USER_AGREEMENT = 'user-agreement',
}

export enum ACCOUNT_NUMBER_MODALS {
    UPDATE = 'update',
    CONFIRM = 'confirm',
}

// TODO Remove Spinwheel on SSP-2840
export enum DIM_NAMES {
    IDENTITY_CONNECT = 'identity-connect',
    KBA = 'debt-connect',
}

// TODO Remove Spinwheel on SSP-2840
export enum DIM_CONTAINER_IDS {
    SPINWHEEL_IDENTITY_CONNECT = 'SPINWHEEL_IDENTITY_CONNECT_DIM_CONTAINER',
    SPINWHEEL_KBA = 'SPINWHEEL_KBA_DIM_CONTAINER',
}
