import { GetPartnerDataResponse } from '../../../types/responseSchema/partnerData';
import { backendAnonymousAxios } from '..';

import { AxiosResponse } from 'axios';

/**
 * Method to retrieve data about a partner
 * when no ID exists on the FE and
 * data needs to be pulled using the "name" field
 *
 * @param partnerName
 *
 * @returns data about the partner, used to power the FE
 */
const getPartnerData = async (partnerSlug: string):
Promise<AxiosResponse<GetPartnerDataResponse | null>> => {
  return await backendAnonymousAxios
    .get<GetPartnerDataResponse | null>(`/partner/${partnerSlug}`);
};

export default getPartnerData;
