import { HelperContent } from './types';

/**
 * Rule used to validate a Field
 */
class Rule<T> {
  /**
   * Friendly description of the rule
   *
   * @private
   */
  #helperText: HelperContent;

  /**
   * Callback function used to validate this Rule
   *
   * @returns `true` pass
   * @returns `false` fail
   * @private
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  #pattern: (value: T) => boolean;

  /**
   * Controls what happens when the pattern fails (returns `false`)
   *
   * @enum `off` means `this.check` passes & `helperText` is not displayed
   * @enum `warn` means `this.check` passes & `helperText` is displayed
   * @enum `error` means `this.check` fails & `helperText` is displayed
   * @private
   */
  #severity: 'off' | 'warn' | 'error';

  /**
   * Construct a new Rule instance
   *
   * @example
   *  mustCapitalizeFirst = new Rule<string>({
   *    helperText: 'first character must be capitalized',
   *    pattern: (value: string) =>
   *      value[0] === value[0].toUpperCase(),
   *    severity: 'error'
   *  })
   */
  constructor({
    helperText,
    pattern,
    severity,
  }: RuleProps<T>) {
    this.#helperText = helperText;
    this.#pattern = pattern;
    this.#severity = severity;
  }

  /**
   * Returns the `helperText` to display on the parent Field
   *  based on if the pattern passes or fails
   *
   * @returns `''` when pattern returns `true` or severity is `'off'`
   * @returns `helperText` when pattern returns `false` and severity is `'error'` or `'warn'`
   */
  helperText(value: T): HelperContent {
    if (this.#severity === 'off')
      return '';

    return this.#pattern(value) ? '' : this.#helperText;
  }

  /**
   * Checks if this Rule is passing (`true`) or failing (`false`)
   *
   * @returns `true` when pattern returns `true` or severity is `'warn'` or `'off'`
   * @returns `false` when pattern returns `false` and severity is `'error'`
   */
  check(value: T): boolean {
    if (this.#severity === 'error')
      return this.#pattern(value);

    return true;
  }
}

export interface RuleProps<T> {
  /**
   * Friendly description of the rule
   */
  helperText: HelperContent;
  /**
   * Callback function used to validate this Rule
   *
   * @returns `true` pass
   * @returns `false` fail
   */
  pattern: (value: T) => boolean;
  /**
   * Controls what happens when the pattern fails (returns `false`)
   *
   * @enum `off` means `this.check` passes & `helperText` is not displayed
   * @enum `warn` means `this.check` passes & `helperText` is displayed
   * @enum `error` means `this.check` fails & `helperText` is displayed
   */
  severity: 'off' | 'warn' | 'error';
}

export default Rule;
