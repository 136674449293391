import { backendAuthenticatedAxios } from '..';

/**
 * Obtains a guest token to authorize requests to Preset's dashboard embed API
 */
const fetchPresetGuestToken = async (): Promise<string> => {
  const response = await backendAuthenticatedAxios.get<PresetGuestTokenResponse>(
    'preset/token',
  );

  return response.data.token;
};

interface PresetGuestTokenResponse {
  token: string;
}

export default fetchPresetGuestToken;
