import { DIMError } from '../../constants/federalRepayments/dimErrors';
import { DIM_ERROR_EVENTS, DIMEvent } from '../../constants/federalRepayments/dimEvents';
import triggerEvent from '../triggerEvent';

const UNIDENTIFIED_DIM_ERROR_ID = 'UNIDENTIFIED_DIM_ERROR_ID';

const LOGROCKET_ERROR_CUSTOM_ID_EVENT = 'repaymentFlowError';

export const addDIMEventOrErrorMetadataToLogRocketSession = (dimSource: DIMEvent | DIMError): void => {
  const logRocketPayload = {
    dimEventName: dimSource?.eventName || UNIDENTIFIED_DIM_ERROR_ID,
    dimErrorMessage: dimSource?.message,
    dimMetadata: JSON.stringify(dimSource?.metadata),
    dimErrorPayload: JSON.stringify(dimSource),
  };

  triggerEvent(LOGROCKET_ERROR_CUSTOM_ID_EVENT, logRocketPayload, { gtm: false, logrocket: true });
};

export const isDIMErrorEvent = (eventName: string): eventName is DIM_ERROR_EVENTS => eventName in DIM_ERROR_EVENTS;
