import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * Creates a piece of state that persists in localStorage,
 *  so state persists between sessions.
 *
 * Based on https://usehooks.com/useLocalStorage/
 *
 * @param storageKey
 *  Storage key used to persist state
 * @param defaultValue
 *  Value used if the storage item is not found
 * @param constructor
 *  (optional) Callback function to convert
 *  parsed item to the proper value type
 *
 * @note Only use `constructor` for types not readable by [JSON.parse()
 *  ](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse)
 *  , including `Date` and other complex objects.
 */
const useLocalState = <StateValue extends unknown, ParsedItem extends unknown>(
  storageKey: string,
  defaultValue: StateValue,
  constructor?: (parsedItem: ParsedItem) => StateValue,
): [ StateValue, Dispatch<SetStateAction<StateValue>> ] => {
  const [ state, setState ] = useState(defaultValue);

  useEffect(() => {
    setState(() => {
      try {
        const item = window.localStorage.getItem(storageKey);

        return item != null
          ? constructor instanceof Function
            ? constructor(JSON.parse(item) as ParsedItem)
            : JSON.parse(item)
          : defaultValue;
      } catch (error) {
        console.error(error);

        return defaultValue;
      }
    });
  }, [ defaultValue, constructor, storageKey ]);

  const setLocalState = (action: SetStateAction<StateValue>) => {
    try {
      const valueToStore = action instanceof Function
        ? action(state)
        : action;

      setState(valueToStore);

      window.localStorage.setItem(
        storageKey,
        JSON.stringify(valueToStore),
      );
    } catch (error) {
      console.log(error);
    }
  };

  return [ state, setLocalState ];
};

export default useLocalState;
