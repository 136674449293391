import { backendAuthenticatedAxios } from '..';

interface VerifyLiveCalculatorResponse {
  is_calculator_live: boolean;
  fpt_uid: string;
  fpt_version: number;
}

const verifyLiveCalculator = async (fptUrlSlug: string): Promise<[boolean, string, number]> => {
  console.log('Checking for live calculator');

  const response = await backendAuthenticatedAxios
    .get<VerifyLiveCalculatorResponse>(`/terms/verify-live-calculator/${fptUrlSlug.toLowerCase()}`).catch(() => {
      console.log(`Error verifying live calculator for FPT URL Slug: ${fptUrlSlug}`);

      // Return a non-live calc response, FE will redirect to error page
      return {
        data: {
          is_calculator_live: false,
          fpt_uid: '',
          fpt_version: 0,
        },
      };
    });

  return [ response.data.is_calculator_live, response.data.fpt_uid, response.data.fpt_version ];
};

export default verifyLiveCalculator;
