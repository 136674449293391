const accordionOverrides = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        margin: '14px 0',
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        alignItems: 'start',
        padding: 0,
        '&.Mui-expanded': {
          minHeight: 'auto',
        },
      },
      content: {
        margin: '14px 0 0',
        '&.Mui-expanded': {
          margin: '14px 0 0',
        },
      },
      expandIconWrapper: {
        marginTop: 14,
        '&.Mui-expanded': {
          marginTop: 14,
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
};

export default accordionOverrides;
