import palette from './palette';

const sliderOverrides = {
  MuiSlider: {
    styleOverrides: {
      marked: {
        marginBottom: '32px',
      },
      thumb: {
        width: '30px',
        height: '30px',
        borderWidth: '4px',
        borderColor: 'white',
        borderStyle: 'solid',
        borderRadius: '15px',
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
      },
      rail: {
        color: palette.grey.A700,
        height: '4px',
      },
      markLabel: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        color: palette.text.secondary,
      },
      mark: {
        backgroundColor: 'transparent',
      },
    },
  },
};

export default sliderOverrides;
