import Form, { Field } from '../../lib/recoil/Form';
import { UsState } from '../../types/eligibility';

const mailAddressConfirmationForm = new Form({
  fields: {
    address_line: new Field<string>({
      key: 'addressLine',
      defaultValue: '',
      persists: false,
      rules: [
        {
          helperText: 'Address is required',
          pattern: (value) => !!value,
          severity: 'error',
        },
      ],
    }),
    address_detail: new Field<string>({
      key: 'addressDetail',
      defaultValue: '',
      persists: false,
      rules: [
        {
          helperText: '',
          pattern: (value) =>
            value !== null,
          severity: 'off',
        },
      ],
    }),
    city: new Field<string>({
      key: 'city',
      defaultValue: '',
      persists: false,
      rules: [
        {
          helperText: 'City is required',
          pattern: (value) => !!value,
          severity: 'error',
        },
      ],
    }),
    state: new Field<UsState | null>({
      key: 'state',
      defaultValue: null,
      persists: false,
      rules: [
        {
          helperText: 'State is required',
          pattern: (value) => !!value,
          severity: 'error',
        },
      ],
    }),
    zip: new Field<string>({
      key: 'zip',
      defaultValue: '',
      persists: false,
      rules: [
        {
          helperText: 'Zip code must be 5 or 9 digits (hyphen or space).',
          pattern: (value) => /^\d{5}(?:[-\s]\d{4})?$/.test(value),
          severity: 'error',
        },
      ],
    }),
  },
});

export default mailAddressConfirmationForm;
