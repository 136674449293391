import { FunctionComponent } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

import useShrinkFieldState from '../../hooks/useShrinkFieldState';
import { Field } from '../../lib/recoil/Form';

interface AppTextFieldProps extends Omit<TextFieldProps, 'type'> {
  field: Field<string>;
  /**
   * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types)
   *  that behaves like a text control
   *
   * @default 'text'
   */
  type?: 'hidden' | 'text' | 'search' | 'tel' | 'url' | 'email' | 'password' | 'number';
  dataCy?: string;
  defaultValue?: string | null;
  valueTransformation?: (value: string) => string; // Function to transform the value before setting it. Useful for formatting specific inputs like phone numbers or currency
}

const AppTextField: FunctionComponent<AppTextFieldProps> = ({
  field,
  dataCy = '',
  defaultValue = null,
  valueTransformation,
  ...props
}) => {
  // Pass the defaultValue to useShrinkFieldState
  const { value, setValue, error, helperText, touched, shrink, setFocused } = useShrinkFieldState<string>(field, defaultValue);

  return (
    <TextField
      InputLabelProps={{ shrink }}
      data-cy={dataCy}
      error={touched && error}
      fullWidth
      helperText={helperText}
      onBlur={() => setFocused(false)}
      onChange={(e) => setValue(valueTransformation ? valueTransformation(e.target.value) : e.target.value)}
      onFocus={() => setFocused(true)}
      value={value ?? ''}  // Fallback to empty string if value is null
      variant='outlined'
      {...props}
    />
  );
};

export default AppTextField;
