import { FunctionComponent } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { Dialog } from '@mui/material';

import useMobileDetect from '../../hooks/useMobileDetect';
import { openInNewTab } from '../../lib/link';
import { externalLinkModalState } from '../../state/atoms/externalLinkModalState';
import InfoModal from '../dataDisplay/InfoModal';

const ExternalLinkModal: FunctionComponent = () => {
  // Get modal state data from recoil
  const [ modalState, setModalState ] = useRecoilState(externalLinkModalState);
  const resetModal = useResetRecoilState(externalLinkModalState);

  const isMobile = useMobileDetect();

  // Width for the modal (dynamic)
  const modalWidth = isMobile ? 300 : 450;

  const modalText = modalState.externalLinkModalText;

  // Helper function to close and reset the modal
  // In a way which doesn't cause a flicker
  const closeAndResetModal = () => {
    // First close the modal
    setModalState({
      isExternalLinkModalOpen: false,
      externalLinkModalText: modalState.externalLinkModalText,
      onContinueUrl: modalState.onContinueUrl,
    });

    // Then completely reset (get rid of the text/continue URL)
    // this avoids a flicker when the modal closes
    setTimeout(() => {
      resetModal();
    }, 500);
  };

  return (
    <Dialog
      onClose={
        () => {
          closeAndResetModal();
        }
      }
      open={modalState.isExternalLinkModalOpen}
    >
      <InfoModal
        cancelCaption={modalText.onCancelText}
        continueCaption={modalText.onContinueText}
        horizontalButtons={!isMobile}  // Horizontally arranged buttons, unless on mobile
        onCancel={
          () => {
            closeAndResetModal();
          }
        }
        onContinue={
          () => {
            openInNewTab(modalState.onContinueUrl);
            closeAndResetModal();
          }
        }
        subtitle={modalText.body}
        title={modalText.title}
        width={modalWidth}
      >
      </InfoModal>
    </Dialog>
  );
};

export default ExternalLinkModal;
