import { FunctionComponent, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Box, Dialog, Grid } from '@mui/material';

import { eventSteps } from '../../constants/bankIntegrationData';
import { initialButtonStates, inProgressButtonStates, successfulButtonStates } from '../../constants/connectBankModalButtonStates';
import flinksModalUserState from '../../state/atoms/flinksModalUserState';
import isBankConnectModalOpen from '../../state/atoms/isBankConnectModalOpen';
import postBankConnectionHandler from '../../state/atoms/postBankConnectionHandler';
import { ConnectBankModalButtonStates } from '../../types/ConnectBankModalButtonStates';
import InfoModal from '../dataDisplay/InfoModal';
import SimpleCheckList from '../dataDisplay/SimpleCheckList';
import FlinksConnectWrapper from '../integrations/flinks/FlinksConnectWrapper';

import checkmarkPic from '../../public/checkbox/checked-round.svg';

const bankConnectBullets = [
  'Instant bank account sync',
  'Simple one-time verification',
  'Safe and secure connection',
];

const ConnectBankModal: FunctionComponent = () => {
  const [ isModalOpen, setIsModalOpen ] = useRecoilState(isBankConnectModalOpen);
  const [ flinksAuthUserState, setFlinksAuthUserState ] = useRecoilState(flinksModalUserState);
  const postModalHandler = useRecoilValue(postBankConnectionHandler);

  const [ continueToFlinks, setContinueToFlinks ] = useState<boolean> (false);
  const [ buttonStates, setButtonStates ] = useState<ConnectBankModalButtonStates>(initialButtonStates);

  useEffect(() => {
    if (flinksAuthUserState?.step === eventSteps.redirect) {
      // Only show finish button on last step of modal
      setButtonStates(successfulButtonStates);
    }
  }, [flinksAuthUserState]);

  // Helper function to reset state of the modal on cancel/finish
  // Using syncronous close -> reset (avoids flicker)
  const closeAndResetModal = () => {
    setIsModalOpen(false);

    setTimeout(() => {
      setContinueToFlinks(false);
      setButtonStates(initialButtonStates);
      setFlinksAuthUserState(null);
    }, 500);
  };

  return (
    <Dialog open={isModalOpen}>
      <InfoModal
        cancelCaption='Skip'
        continueCaption={'Sync your account'}
        height={318}
        onCancel={
          () => {
            closeAndResetModal();
            postModalHandler.cancelHandler();
          }
        }
        onContinue={
          () => {
            setContinueToFlinks(true);
            setButtonStates(inProgressButtonStates);
          }
        }
        onSuccess={
          () => {
            closeAndResetModal();
            postModalHandler.successHandler();
          }
        }
        showCancelButton={buttonStates.showCancel}
        showContinueButton={buttonStates.showContinue}
        showSuccessButton={buttonStates.showSuccess}
        title={!continueToFlinks ? 'Sync your bank account!' : ''}
        titleAlign='center'
      >
        {
          continueToFlinks
            ? (
              <Grid container justifyContent='center'>
                <FlinksConnectWrapper />
              </Grid>
            )
            : (
              <Box px={2}>
                <SimpleCheckList
                  horizontalGap={1.5}
                  icon={checkmarkPic}
                  iconOffset={0.5}
                  iconSize={20}
                  items={bankConnectBullets}
                  verticalGap={2.5}
                />
              </Box>
            )
        }
      </InfoModal>
    </Dialog>
  );
};

export default ConnectBankModal;
