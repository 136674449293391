import { StudentSponsorshipApplication } from '../../../types/sponsorshipApplication';
import { backendAuthenticatedAxios } from '../';

import { AxiosResponse } from 'axios';

const submitCommonApplicationProfile = async (sponsorshipApplicationId: string): Promise<AxiosResponse<StudentSponsorshipApplication>> => {
  return backendAuthenticatedAxios.patch<StudentSponsorshipApplication>(`/esl-student/${sponsorshipApplicationId}/submit-common-application-profile`);
};

export default submitCommonApplicationProfile;
