import Form, { Field } from '../../../lib/recoil/Form';
import { SponsorshipApplicationTemporalFiles } from '../../../types/documentsSubmit';

/**
 * Manages the state of the program questions form
 * TODO - Add Separate Fields for Each Uploaded Document in documentsSubmitForm SSP-2612
 */
const documentsSubmitForm = new Form({
  fields: {
    documentFiles: new Field<SponsorshipApplicationTemporalFiles>({
      key: 'documentFiles',
      defaultValue: {},
      persists: false,
      rules: [
        {
          helperText: 'Files are required',
          pattern: (value) =>
            value !== null,
          severity: 'error',
        },
      ],
    }),
  },
});

export default documentsSubmitForm;
